import React, { Component } from "react";
import { Grid, Container, Image, Divider } from "semantic-ui-react";

export default class SingleLayout extends Component {
  render = () => {
    return (
      <Container style={{ margin: 20 }}>
        <Grid
          textAlign="center"
          style={{ height: "90vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }} textAlign={"left"}>
            <Image src={"/logo-banner.png"} />
            <Divider hidden />
            {this.props.children}
          </Grid.Column>
        </Grid>
      </Container>
    );
  };
}
