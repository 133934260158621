import React, { Fragment } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import StartPage from "./start";
import DashboardPage from "./dashboard";
import ProfilePage from "./profile";
import MessagePage from "./message";
import LoansPage from "./loans";
import LoanNewPage from "./loans/new";
import LookupLiborRatesPage from "./lookup-libor-rates";
import PledgesPage from "./pledges";
import AccountsPage from "./accounts";
import LogoutPage from "./logout";
import InterestCalculatorPage from "./calculator";

import { AuthenticatedRoute } from "../components/authenticated-route";
import LoanViewPage from "./loans/view";
import ContactPage from "./contact";

const MainPage = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/pages/start" component={StartPage} />
        <AuthenticatedRoute path="/pages/dashboard" component={DashboardPage} />
        <AuthenticatedRoute exact path="/pages/loans" component={LoansPage} />
        <AuthenticatedRoute
          exact
          path="/pages/loans/new"
          component={LoanNewPage}
        />
        <AuthenticatedRoute path="/pages/pledges" component={PledgesPage} />
        <AuthenticatedRoute path="/pages/accounts" component={AccountsPage} />
        <AuthenticatedRoute
          path="/pages/calculator"
          component={InterestCalculatorPage}
        />
        <AuthenticatedRoute
          path="/pages/lookup-libor-rates"
          component={LookupLiborRatesPage}
        />
        <Route path="/pages/contact" component={ContactPage} />
        <AuthenticatedRoute
          exact
          path="/pages/profile"
          render={(props) => <ProfilePage {...props} isAdmin={false} />}
        />
        <AuthenticatedRoute
          path="/pages/admin/profile"
          render={(props) => <ProfilePage {...props} isAdmin />}
        />
        <AuthenticatedRoute
          path="/pages/loans/:loan_id"
          component={LoanViewPage}
        />
        <Route path="/pages/message" component={MessagePage} />
        <Route path="/pages/logout" component={LogoutPage} />
        <Route exact path="/" render={() => <Redirect to="/pages/start" />} />
      </Switch>
    </Fragment>
  );
};

export default MainPage;
