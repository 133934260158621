import React, { Component } from "react";
import SingleGridLayout from "../layout/single-grid";
import { Segment } from "semantic-ui-react";

const title = "Message!";

export default class MessagePage extends Component {
  state = {
    message: "",
  };

  componentDidMount = () => {
    if (localStorage.getItem("message") !== null)
      this.setState(() => ({ message: localStorage.getItem("message") }));
  };

  render = () => {
    const { message } = this.state;

    return (
      <SingleGridLayout title={title}>
        <Segment color={"red"}>{message}</Segment>
      </SingleGridLayout>
    );
  };
}
