import axios from "axios";

export const LoanService = {
  get_loans: (active, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans?active=${active}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_loan: (loanId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans/${loanId}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  complete_loan: (loanId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/loans/${loanId}/complete`, {}, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  default_loan: (loanId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/loans/${loanId}/default`, {}, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  archive_loan: (loanId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/loans/${loanId}/archive`, {}, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_overdue_loans: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/loans/overdue", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_margin_triggered_loans: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/loans/margin-triggered", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_alert_triggered_loans: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/loans/alert-triggered", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_volume_triggered_loans: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/loans/volume-triggered", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_currency_triggered_loans: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/loans/currency-triggered", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  save_loan: (loan_new, pledge_new, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    let start_date = loan_new.start_date + "T00:00:00.000";
    loan_new.preview_date = start_date;
    loan_new.active_date = start_date;
    //
    axios
      .post(
        `/api/loans`,
        { loan_new: loan_new, pledge_new: pledge_new },
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },
  extend_loan: (loanId, loan_extension_new, onSuccess, onError) => {
    let payload = {
      interest_rate: Number(loan_extension_new.interest_rate),
      late_interest_rate: Number(loan_extension_new.late_interest_rate),
      initial_payment_percentage: Number(
        loan_extension_new.initial_payment_percentage
      ),
      repayment_months: Number(loan_extension_new.repayment_months),
    };
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(`/api/loans/${loanId}/extend`, payload, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  calculate_payments: (loan, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    let payload = {
      interest_rate: loan.interest_rate,
      late_interest_rate: loan.late_interest_rate,
      interest: 0,
      late_interest: 0,
      principal: loan.principal,
      initial_payment_percentage: loan.initial_payment_percentage,
      repayment_months: loan.repayment_months,
      active_date: loan.active_date + "T00:00:00.000",
    };
    axios
      .post("/api/loans/calculate-payments", payload, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
};
