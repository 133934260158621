import React, { Component, Fragment } from "react";
import { Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class DataLoader extends Component {
  render = () => {
    const { t, isLoading, notFoundMessage, itemsLength } = this.props;
    return (
      <Fragment>
        {isLoading && (
          <Message color={"teal"}>
            <Message.Header>{t("general-loading-data")}</Message.Header>
          </Message>
        )}
        {!isLoading && itemsLength === 0 && (
          <Message negative>
            <Message.Header>{t("general-no-data")}</Message.Header>
            <p>{notFoundMessage}</p>
          </Message>
        )}
      </Fragment>
    );
  };
}

export default withTranslation("translations")(DataLoader);
