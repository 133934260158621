import React from "react";
import { Modal, Header, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

export const ConfirmationDialog = ({
  t,
  open,
  message,
  description,
  onYesClick,
  onNoClick,
}) => {
  return (
    <Modal open={open}>
      <Header
        icon={"question circle outline"}
        color={"red"}
        content={"Are you sure?"}
      />
      <Modal.Content>
        <p>
          {message || "Are you sure you want to delete this particular record?"}
        </p>
        <p>
          <b>{description || "This action cannot be undone."}</b>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button name={"noBtn"} color="red" inverted onClick={onNoClick}>
          <Icon name="cancel" /> {(t && t("button-no")) || "No"}
        </Button>
        <Button name={"yesBtn"} color="green" inverted onClick={onYesClick}>
          <Icon name="checkmark" /> {(t && t("button-yes")) || "Yes"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
};
