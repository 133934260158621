import React, { Component, Fragment } from "react";
import { Segment, Label, Form, Button } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { UserService } from "../../services/user-service";
import { MessageDialog } from "../../components/message-dialog";

import { withTranslation } from "react-i18next";

class ProfilePage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    password: "",
    saveLabel: "button-save",
    dialogOkLabel: "profile-update-dialog-ok",
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  componentDidMount = () => {};

  /* Saving and  Message Dialog */
  doSubmit = () => {
    const { password } = this.state;
    this.setState({ password: "" });
    if (password.trim() === "") {
      return;
    }
    UserService.changePassword(password, this.onSaveSuccess, this.onSaveError);
  };

  onSaveSuccess = () => {
    const { t } = this.props;

    let dialogMessage = "Your Password has been changed.";
    this.setState({
      password: "",
      dialogOpen: true,
      dialogTitle: t("profile-save-success"),
      dialogMessage: dialogMessage,
      isError: false,
    });
  };

  onSaveError = (error) =>
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Changing Password",
      dialogMessage:
        "There's an error changing your password. Please contact administrator.",
      dialogDescription: "",
      isError: true,
    });

  onMessageDialogOk = () => this.setState({ dialogOpen: false });

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      dialogOkLabel,
      isError,
      saveLabel,
      password,
    } = this.state;

    const { t } = this.props;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          okLabel={t(dialogOkLabel)}
          onOkClick={this.onMessageDialogOk}
        />

        <SingleGridLayout isAdmin={this.props.isAdmin}>
          <Form id={"individual-loan-form"} size="large">
            <Segment padded>
              <Label attached={"top"} color={"red"}>
                {t("profile-change-password")}
              </Label>
              <Form.Input
                label={t("profile-change-password-instruction")}
                name={"password"}
                type={"password"}
                value={password}
                onChange={this.handleChange}
              />
              <Segment basic textAlign={"right"}>
                <Button color={"blue"} onClick={this.doSubmit}>
                  {t(saveLabel)}
                </Button>
              </Segment>
            </Segment>
          </Form>
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(ProfilePage);
