import React, { Component, Fragment } from "react";
import {
  Form,
  Segment,
  Button,
  Message,
  Label,
  Divider,
} from "semantic-ui-react";
import SingleLayout from "../layout/single";
import { UserService } from "../services/user-service";
import { MessageDialog } from "../components/message-dialog";
import DataConfirmationDialog from "../components/data-confirmation-dialog";

import { withTranslation } from "react-i18next";

class StartPage extends Component {
  state = {
    email: "",
    password: "",
    dialogOpen: false,
    dialogTitle: "Profile Update Required",
    dialogMessage:
      "You appear not to have an profile. An up-to-date profile is required for any subscriptions or loan requests to be acknowledged and processed.",
    dialogDescription:
      "You will now be brought to your profile page. Thank you for your interest.",
    isError: false,
    invalidLogin: false,
    disabledLogin: false,
    showPasswordReset: false,
    dataConfirmDialogOpen: false,
    dataConfirmDialogValue: "",
    termsAndConditionsDialogOpen: false,
  };

  componentDidMount = () => {
    const { t } = this.props;
    this.setState({
      dialogTitle: t("profile-update-title"),
      dialogMessage: t("profile-update-message"),
      dialogDescription: t("profile-update-description"),
    });
  };

  doLogin = () => {
    const { email, password } = this.state;
    const { t } = this.props;
    UserService.login(
      email,
      password,
      (response) => {
        let from = localStorage.getItem("from");
        sessionStorage.setItem("token", response.data.access_token);
        if (from) {
          localStorage.removeItem("from");
          this.props.history.push(from);
        } else {
          this.props.history.push("/pages/dashboard");
        }
      },
      (error) => {
        let messageFunc = (error_response) => {
          switch (error_response.status) {
            case 400:
              return {
                invalidLogin: true,
                disabledLogin: false,
                invalidLoginMessage: t("start-login-fail-message"),
              };
            case 422:
              return {
                invalidLogin: true,
                disabledLogin: false,
                invalidLoginMessage: t("start-login-fail-message"),
              };
            default:
              return {
                invalidLogin: true,
                disabledLogin: false,
                invalidLoginMessage: t("start-login-unknown"),
              };
          }
        };
        this.setState(messageFunc(error.response));
      }
    );
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleClick = () => this.doLogin();

  onMessageDialogOk = () => {
    localStorage.setItem("firstLogin", "true");
    this.props.history.push("/pages/profile");
  };

  resetPassword = () => this.setState({ dataConfirmDialogOpen: true });

  onDataConfirmDialogYesClick = (descriptionData) => {
    this.setState({ dataConfirmDialogOpen: false });
    UserService.resetPassword(
      descriptionData.description,
      this.onResetPasswordSuccess,
      this.onResetPasswordError
    );
  };

  onDataConfirmDialogNoClick = () =>
    this.setState({ dataConfirmDialogOpen: false, dataConfirmDialogValue: "", });

  onResetPasswordSuccess = () => this.setState({ showPasswordReset: true, dataConfirmDialogValue: "" });

  onResetPasswordError = (error) =>
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Resetting Password",
      dialogMessage: this.props.t("start-password-reset-error"),
      dialogDescription: error.message,
      isError: true,
      dataConfirmDialogValue: "",
    });

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
      invalidLogin,
      disabledLogin,
      invalidLoginMessage,
      showPasswordReset,
      dataConfirmDialogOpen,
      dataConfirmDialogValue,
    } = this.state;

    const { t } = this.props;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />

        <DataConfirmationDialog
          open={dataConfirmDialogOpen}
          title={t("start-password-reset")}
          description={t("start-password-reset-instruction")}
          onNoClick={this.onDataConfirmDialogNoClick}
          onYesClick={this.onDataConfirmDialogYesClick}
          initialValue={dataConfirmDialogValue}
        />

        <SingleLayout>
          {(invalidLogin || disabledLogin) && (
            <Message negative={invalidLogin} warning={disabledLogin}>
              <Message.Header>{t("start-login-fail")}</Message.Header>
              {invalidLoginMessage}
            </Message>
          )}

          {showPasswordReset && (
            <Message positive>
              <Message.Header>
                Password Reset Request Successful.
              </Message.Header>
              <p>
                If you are registered with our Office Portal, an email containing your
                new password will be sent to you.
              </p>
            </Message>
          )}

          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder={t("start-email")}
                name={"email"}
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder={t("start-password")}
                type="password"
                name={"password"}
                onChange={this.handleChange}
              />

              <Button primary fluid size="large" onClick={this.handleClick}>
                {t("start-login")}
              </Button>
              <Segment basic textAlign={"center"}>
                {t("start-forgot-password")}{" "}
                <Label onClick={this.resetPassword}>
                  {t("start-new-password")}
                </Label>
              </Segment>
            </Segment>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
          </Form>
        </SingleLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(StartPage);
