import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import approve from "approvejs";

class ChangeAlertsDialog extends Component {
  state = {
    pledge: {},
  };

  descriptionData = {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.pledge !== prevProps.pledge)
      this.setState({
        pledge: this.props.pledge,
      });
  };

  onError = (error) => console.log(error);

  handleChange = (e, { name, value }) => {
    const { pledge } = this.state;
    pledge[name] = value;
    this.setState({ pledge: pledge });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidate = () => {
    const { pledge } = this.state;
    const {
      volume_trigger,
      margin_percentage,
      alert_percentage,
      currency_trigger,
    } = pledge;

    let requiredRule = {
      required: true,
    };

    let isValid3 = this.doValidateImpl(
      volume_trigger,
      requiredRule,
      "volume_trigger_error",
      "Please enter the Volume Alert."
    );
    let isValid4 = this.doValidateImpl(
      margin_percentage,
      requiredRule,
      "margin_percentage_error",
      "Please enter the Margin Percentage."
    );
    let isValid5 = this.doValidateImpl(
      alert_percentage,
      requiredRule,
      "alert_percentage_error",
      "Please enter the Alert Percentage."
    );
    let isValid6 = this.doValidateImpl(
      currency_trigger,
      requiredRule,
      "currency_trigger_error",
      "Please enter the Currency Trigger."
    );
    return isValid3 && isValid4 && isValid5 && isValid6;
  };

  doSubmit = (onAfterSave) => {
    if (!this.doValidate()) return;

    const { pledge } = this.state;

    onAfterSave(pledge);
  };
  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;

    const {
      pledge,
      volume_trigger_error,
      margin_percentage_error,
      alert_percentage_error,
      currency_trigger_error,
    } = this.state;
    const {
      volume_trigger,
      margin_percentage,
      alert_percentage,
      native_currency,
      currency_trigger,
    } = pledge;
    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Change Alerts"}
        />
        <Modal.Content>
          <p>
            Please set the percentage cutoffs for alerts and/or margins. You can
            also set the volume cutoff.
          </p>
          <Form id={"description-form"} size="large">
            <Form.Input
              label={"Volume Alert"}
              name={"volume_trigger"}
              value={volume_trigger}
              type={"number"}
              step={"50000"}
              error={volume_trigger_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Margin Percentage"}
              name={"margin_percentage"}
              value={margin_percentage}
              type={"number"}
              step={"0.1"}
              error={margin_percentage_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Alert Percentage"}
              name={"alert_percentage"}
              value={alert_percentage}
              type={"number"}
              step={"0.1"}
              error={alert_percentage_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Native Currency"}
              name={"native_currency"}
              value={native_currency}
              readOnly
            />
            <Form.Input
              label={"Currency Trigger"}
              name={"currency_trigger"}
              value={currency_trigger}
              type={"number"}
              step={"0.1"}
              error={currency_trigger_error}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={"noBtn"} color="red" inverted onClick={onCancelClick}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => this.doSubmit(onYesClick)}
          >
            <Icon name="checkmark" /> Change Alerts
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

ChangeAlertsDialog.propTypes = {
  open: PropTypes.bool,
  pledge: PropTypes.object,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default ChangeAlertsDialog;
