import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserService } from "../services/user-service";

export const AuthenticatedRoute = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [administrator, setAdministrator] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let _mounted = true;

    const onCurrentUser = (currentUser) => {
      if (_mounted) {
        if (currentUser) {
          setAuthenticated(true);
          localStorage.setItem("userId", currentUser.id);
          setAdministrator(currentUser.is_superuser);
        }
        setLoading(false);
      }
    };

    const onCurrentUserError = (error) => {
      setAuthenticated(false);
      setAdministrator(false);
      setLoading(false);
      localStorage.removeItem("userId");
      sessionStorage.removeItem("token");
    };

    const checkIsValidLogin = async () =>
      UserService.currentUser(onCurrentUser, onCurrentUserError);
    checkIsValidLogin();

    return () => {
      _mounted = false;
    };
  });

  if (loading) return "";

  if (authenticated && (props.adminOnly ? administrator : true))
    return <Route {...props} />;
  else {
    localStorage.setItem("from", props.location.pathname);
    return (
      <Redirect
        to={{ pathname: "/pages/start", state: { from: props.location } }}
      />
    );
  }
};
