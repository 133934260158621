import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

class RepaymentDialog extends Component {
  state = {
    is_late: false,
    repayment: {},
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.repayment !== prevProps.repayment) {
      this.setState({
        repayment: this.props.repayment,
      });
    }
  };

  handleChange = (e, { name, value }) => {
    const { is_late } = this.state;
    this.setState({ is_late: !is_late });
  };

  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;
    const { is_late, repayment } = this.state;
    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Log Interest Repayment"}
        />
        {!repayment.late_interest_applied && (
          <Modal.Content>
            <p>
              If you want to apply a Late Fees penalty on the Interest
              Repayment, check the Late box to Flag it.
            </p>
            <p>Otherwise, click on the Paid button to mark it as paid..</p>
            <p>Both Actions are not reversible. Are you sure?</p>
            <Form id={"description-form"} size="large">
              <Form.Checkbox
                name={"is_late"}
                label={"Payment is LATE."}
                checked={is_late}
                onChange={this.handleChange}
              />
            </Form>
          </Modal.Content>
        )}
        {repayment.late_interest_applied && (
          <Modal.Content>
            <p>
              A Late Fees penalty on the Interest Repayment has been applied
            </p>
            <p>Click on the Paid button to mark it as paid..</p>
            <p>This action is not reversible. Are you sure?</p>
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button name={"noBtn"} color="red" inverted onClick={onCancelClick}>
            <Icon name="cancel" /> Cancel
          </Button>
          {is_late && !repayment.late_interest_applied && (
            <Button
              name={"yesBtn"}
              color="red"
              inverted
              onClick={() => onYesClick(true)}
            >
              <Icon name="checkmark" /> Flag as LATE
            </Button>
          )}
          {(!is_late || repayment.late_interest_applied) && (
            <Button
              name={"yesBtn"}
              color="green"
              inverted
              onClick={() => onYesClick(false)}
            >
              <Icon name="checkmark" /> Paid
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  };
}

RepaymentDialog.propTypes = {
  open: PropTypes.bool,
  repayment: PropTypes.object,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default RepaymentDialog;
