export const Thousander = new Intl.NumberFormat("en-US", {});

export const StringUtils = {
  capitalize: (string) =>
    string
      ? string[0]
        ? `${string[0].toUpperCase()}${string.substring(1)}`
        : string
      : "",
  upCase: (string) =>
    string ? (string[0] ? `${string.toString().toUpperCase()}` : string) : "",
  denull: (string) =>
    string === null || typeof string === "undefined" ? "" : string,
  humanizeBoolean: (bool) => (bool ? "Yes" : "No"),
  toISOString: (date) => {
    if (date === null || typeof date === "undefined") return "";
    return new Date(date).toISOString().split("T")[0];
  },
  toLocaleDateString: (date, null_message) => {
    if (date === null || typeof date === "undefined") {
      if (null_message) {
        return null_message;
      } else {
        return "";
      }
    }
    return new Date(date).toLocaleDateString();
  },
  emailToName: (email) => {
    let names = email.split("@")[0].split(".");
    return names.map((name) => StringUtils.capitalize(name)).join(" ");
  },
};
