import React, { Component, Fragment } from "react";
import { Icon, Label, Menu, Segment, Table } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { AccountService } from "../../services/account-service";
import { MessageDialog } from "../../components/message-dialog";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import DataLoader from "../../components/data-loader";
import AccountFormDialog from "./components/account-form-dialog";
import { withTranslation } from "react-i18next";

class AccountsPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    activeAccounts: [],
    isActiveAccountsLoading: true,
    inactiveAccounts: [],
    isInactiveAccountsLoading: true,
    activeItem: "active",
    accountFormDialogOpen: false,
    currentAccount: {},
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  loadData = () => {
    AccountService.get_accounts(
      true,
      this.onActiveAccountsListSuccess,
      this.onError
    );
    AccountService.get_accounts(
      false,
      this.onInactiveAccountsListSuccess,
      this.onInactiveError
    );
  };

  componentDidMount = () => this.loadData();

  onActiveAccountsListSuccess = (listItems) => {
    this.setState({
      activeAccounts: listItems.data,
      isActiveAccountsLoading: false,
    });
  };

  onInactiveAccountsListSuccess = (listItems) => {
    this.setState({
      inactiveAccounts: listItems.data,
      isInactiveAccountsLoading: false,
    });
  };

  onError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Accounts",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isActiveAccountsLoading: false,
    });
  };

  onInactiveError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Accounts",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isInactiveAccountsLoading: false,
    });
  };

  onMessageDialogOk = (e, { name }) => {
    const { isError } = this.state;
    this.setState({ dialogOpen: false });

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewLoan = (loanId) =>
    this.props.history.push(`/pages/loan-request-view/${loanId}`);

  getRefTranslationKey = (input) => {
    return `button-${input}`;
  };

  /* Account */
  newAccount = () => this.setState({ accountFormDialogOpen: true });

  onAccountFormDialogYesClick = (account_data) => {
    if (account_data.id === undefined) {
      AccountService.save_account(
        account_data,
        this.onAccountSaveSuccess,
        this.onError
      );
    } else {
      AccountService.update_account(
        account_data,
        this.onAccountSaveSuccess,
        this.onError
      );
    }
  };

  onAccountSaveSuccess = () => {
    this.setState({ accountFormDialogOpen: false, currentAccount: {} });
    this.loadData();
  };

  onAccountFormDialogCancelClick = () =>
    this.setState({ accountFormDialogOpen: false, currentAccount: {} });

  editAccount = (account) => {
    let cAccount = {};
    Object.assign(cAccount, account);
    this.setState({ accountFormDialogOpen: true, currentAccount: cAccount });
  };

  archiveAccount = (loanId) => {
    this.setState({ confirmationDialogOpen: true, archiveAccountId: loanId });
  };

  onConfirmationDialogYesClick = () => {
    const { archiveAccountId } = this.state;
    AccountService.archive_account(
      archiveAccountId,
      this.accountArchived,
      this.onError
    );
  };
  accountArchived = () => {
    this.setState({ confirmationDialogOpen: false, archiveAccountId: null });
    this.loadData();
  };
  onConfirmationDialogNoClick = () =>
    this.setState({ confirmationDialogOpen: false, archiveAccountId: null });

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
      isActiveAccountsLoading,
      activeAccounts,
      isInactiveAccountsLoading,
      inactiveAccounts,
      activeItem,
      accountFormDialogOpen,
      currentAccount,
      confirmationDialogOpen,
    } = this.state;

    return (
      <Fragment>
        <AccountFormDialog
          open={accountFormDialogOpen}
          account={currentAccount}
          onYesClick={this.onAccountFormDialogYesClick}
          onCancelClick={this.onAccountFormDialogCancelClick}
        />
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          message={"Are you sure you want to Archive this Account?"}
          onYesClick={this.onConfirmationDialogYesClick}
          onNoClick={this.onConfirmationDialogNoClick}
        />

        <SingleGridLayout>
          <Segment basic textAlign={"right"}>
            <Icon
              name={"plus"}
              color={"blue"}
              bordered
              inverted
              onClick={this.newAccount}
            />
          </Segment>

          {
            <Menu pointing secondary>
              <Menu.Item
                name="active"
                active={activeItem === "active"}
                onClick={this.handleItemClick}
              >
                Active Accounts
              </Menu.Item>

              <Menu.Item
                name="inactive"
                active={activeItem === "inactive"}
                onClick={this.handleItemClick}
              >
                Archived Accounts
              </Menu.Item>
            </Menu>
          }

          {activeItem === "active" && (
            <Fragment>
              <DataLoader
                isLoading={isActiveAccountsLoading}
                itemsLength={activeAccounts.length}
                notFoundMessage={"There are No Active Accounts."}
              />
              {!isActiveAccountsLoading && activeAccounts.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Active Accounts
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Contact</Table.HeaderCell>
                        <Table.HeaderCell>
                          Active Loans / Total Loans
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {activeAccounts.map((account) => (
                        <Table.Row key={account.id}>
                          <Table.Cell>{account.name}</Table.Cell>
                          <Table.Cell>{account.email}</Table.Cell>
                          <Table.Cell>{account.contact}</Table.Cell>
                          <Table.Cell>
                            {account.active_loans} / {account.total_loans}
                          </Table.Cell>
                          <Table.Cell>
                            <Icon
                              name={"edit"}
                              color="blue"
                              onClick={() => this.editAccount(account)}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {account.active_loans === 0 && (
                              <Icon
                                name={"minus"}
                                color="red"
                                onClick={() => this.archiveAccount(account.id)}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}

          {activeItem === "inactive" && (
            <Fragment>
              <DataLoader
                isLoading={isInactiveAccountsLoading}
                itemsLength={activeAccounts.length}
                notFoundMessage={"There are No Active Accounts."}
              />
              {!isInactiveAccountsLoading && inactiveAccounts.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Archived Accounts
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Contact</Table.HeaderCell>
                        <Table.HeaderCell>
                          Active Loans / Total Loans
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {inactiveAccounts.map((account) => (
                        <Table.Row key={account.id}>
                          <Table.Cell>{account.name}</Table.Cell>
                          <Table.Cell>{account.email}</Table.Cell>
                          <Table.Cell>{account.contact}</Table.Cell>
                          <Table.Cell>
                            {account.active_loans} / {account.total_loans}
                          </Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(AccountsPage);
