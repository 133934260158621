import axios from "axios";

export const PledgeService = {
  get_pledges: (active, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/pledges?active=${active}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_pledge_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/pledge?loan_id=${loan_id}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_pledge_additions_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/pledge-additions?loan_id=${loan_id}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  save_pledge_addition: (loan_id, pledge_addition, onSuccess, onError) => {
    let payload = {
      loan_id: loan_id,
      volume: Number(pledge_addition.volume),
      price: Number(pledge_addition.price),
      native_price: Number(pledge_addition.native_price),
    };
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(`/api/pledge-additions`, payload, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  update_pledge_alert: (pledge_alert, onSuccess, onError) => {
    let payload = {
      alert_percentage: Number(pledge_alert.alert_percentage),
      margin_percentage: Number(pledge_alert.margin_percentage),
      volume_trigger: Number(pledge_alert.volume_trigger),
      currency_trigger: Number(pledge_alert.currency_trigger),
    };
    let pledge_id = pledge_alert.id;

    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/pledges/${pledge_id}`, payload, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
};
