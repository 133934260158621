import axios from "axios";

export const AccountService = {
  get_accounts: (active, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/accounts?active=${active}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_active_basic_accounts: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/accounts/active-basic", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  save_account: (account, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(`/api/accounts`, account, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  update_account: (account, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/accounts`, account, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  archive_account: (accountId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(`/api/accounts/${accountId}/archive`, {}, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
};
