import React, { Component, Fragment } from "react";
import { Button, Icon, Label, Menu, Segment, Table } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { PledgeService } from "../../services/pledge-service";
import { MessageDialog } from "../../components/message-dialog";
import DataLoader from "../../components/data-loader";

import { withTranslation } from "react-i18next";

class PledgesPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    activePledges: [],
    isActivePledgesLoading: true,
    inactivePledges: [],
    isInactivePledgesLoading: true,
    activeItem: "active",
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  loadData = () => {
    PledgeService.get_pledges(
      true,
      this.onActivePledgesListSuccess,
      this.onError
    );
    PledgeService.get_pledges(
      false,
      this.onInactivePledgesListSuccess,
      this.onInactiveError
    );
  };

  componentDidMount = () => this.loadData();

  onActivePledgesListSuccess = (listItems) => {
    this.setState({
      activePledges: listItems.data,
      isActivePledgesLoading: false,
    });
  };

  onInactivePledgesListSuccess = (listItems) => {
    this.setState({
      inactivePledges: listItems.data,
      isInactivePledgesLoading: false,
    });
  };

  onError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Pledges",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isActivePledgesLoading: false,
    });
  };

  onInactiveError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Pledges",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isInactivePledgesLoading: false,
    });
  };

  onMessageDialogOk = (e, { name }) => {
    const { isError } = this.state;
    this.setState({ dialogOpen: false });

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewLoan = (loanId) => this.props.history.push(`/pages/loans/${loanId}`);

  newLoan = () => this.props.history.push("/pages/loans/new");

  getRefTranslationKey = (input) => {
    return `button-${input}`;
  };

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
      isActivePledgesLoading,
      activePledges,
      isInactivePledgesLoading,
      inactivePledges,
      activeItem,
    } = this.state;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />

        <SingleGridLayout>
          <Segment basic textAlign={"right"}>
            <Button color={"blue"} onClick={this.newLoan}>
              <Icon name={"plus"} />
            </Button>
          </Segment>

          {
            <Menu pointing secondary>
              <Menu.Item
                name="active"
                active={activeItem === "active"}
                onClick={this.handleItemClick}
              >
                Active Pledges
              </Menu.Item>

              <Menu.Item
                name="inactive"
                active={activeItem === "inactive"}
                onClick={this.handleItemClick}
              >
                Archived Pledges
              </Menu.Item>
            </Menu>
          }

          {activeItem === "active" && (
            <Fragment>
              <DataLoader
                isLoading={isActivePledgesLoading}
                itemsLength={activePledges.length}
                notFoundMessage={"There are No Active Pledges."}
              />
              {!isActivePledgesLoading && activePledges.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Active Pledges
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell>Security Code</Table.HeaderCell>
                        <Table.HeaderCell>Volume</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Pledge Value</Table.HeaderCell>
                        <Table.HeaderCell>Loan</Table.HeaderCell>
                        <Table.HeaderCell>Volume Alert</Table.HeaderCell>
                        <Table.HeaderCell>Alert %</Table.HeaderCell>
                        <Table.HeaderCell>Margin %</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {activePledges.map((pledge) => (
                        <Table.Row key={pledge.id}>
                          <Table.Cell>{pledge.account_name}</Table.Cell>
                          <Table.Cell>{pledge.security_code}</Table.Cell>
                          <Table.Cell>{pledge.volume}</Table.Cell>
                          <Table.Cell>{pledge.price}</Table.Cell>
                          <Table.Cell>{pledge.pledge_value}</Table.Cell>
                          <Table.Cell>{pledge.principal}</Table.Cell>
                          <Table.Cell>{pledge.volume_trigger}</Table.Cell>
                          <Table.Cell>{pledge.alert_percentage}</Table.Cell>
                          <Table.Cell>{pledge.margin_percentage}</Table.Cell>
                          <Table.Cell>
                            <Icon
                              name={"edit"}
                              color="blue"
                              onClick={() => this.viewLoan(pledge.loan_id)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}

          {activeItem === "inactive" && (
            <Fragment>
              <DataLoader
                isLoading={isInactivePledgesLoading}
                itemsLength={activePledges.length}
                notFoundMessage={"There are No Active Pledges."}
              />
              {!isInactivePledgesLoading && inactivePledges.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Archived Pledges
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell>Security Code</Table.HeaderCell>
                        <Table.HeaderCell>Volume</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Pledge Value</Table.HeaderCell>
                        <Table.HeaderCell>Loan</Table.HeaderCell>
                        <Table.HeaderCell>Volume Alert</Table.HeaderCell>
                        <Table.HeaderCell>Alert %</Table.HeaderCell>
                        <Table.HeaderCell>Margin %</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {inactivePledges.map((pledge) => (
                        <Table.Row key={pledge.id}>
                          <Table.Cell>{pledge.account_name}</Table.Cell>
                          <Table.Cell>{pledge.security_code}</Table.Cell>
                          <Table.Cell>{pledge.volume}</Table.Cell>
                          <Table.Cell>{pledge.price}</Table.Cell>
                          <Table.Cell>{pledge.pledge_value}</Table.Cell>
                          <Table.Cell>{pledge.principal}</Table.Cell>
                          <Table.Cell>{pledge.volume_trigger}</Table.Cell>
                          <Table.Cell>{pledge.alert_percentage}</Table.Cell>
                          <Table.Cell>{pledge.margin_percentage}</Table.Cell>
                          <Table.Cell>
                            <Icon
                              name={"edit"}
                              color="blue"
                              onClick={() => this.viewLoan(pledge.loan_id)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(PledgesPage);
