import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./locales/en.json";
import translationCn from "./locales/cn.json";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en-GB",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
      useSuspense: false,
    },
    resources: {
      "en-US": {
        translations: translationEn,
      },
      en: {
        translations: translationEn,
      },
      "en-GB": {
        translations: translationEn,
      },
      "zh-CN": {
        translations: translationCn,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
