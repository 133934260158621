import axios from "axios";

export const LookupLiborRateService = {
  get_lookup_libor_rates: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/lookup/libor-rates`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  get_current_lookup_libor_rate: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/lookup/current-libor-rate", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  new_current_lookup_libor_rate: (libor_rate, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(
        "/api/lookup/current-libor-rate",
        { libor_rate: libor_rate },
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },
};

export const SecurityCodeInfoService = {
  get_security_code_info: (symbol, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/lookup/security-code/${symbol}`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
};
