import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import { LookupLiborRateService } from "../../../services/lookup-service";
import approve from "approvejs";

class ExtendLoanDialog extends Component {
  state = {
    interest_rate: 12,
    late_interest_rate: 12,
    initial_payment_percentage: 2,
    repayment_months: 12,
    current_libor_rate: 0,
    interest_rate_error: false,
    late_interest_rate_error: false,
    initial_payment_percentage_error: false,
    repayment_months_error: false,
  };

  loadCurrentLiborRate = () =>
    LookupLiborRateService.get_current_lookup_libor_rate(
      this.onLiborRateLoaded,
      this.onError
    );

  onLiborRateLoaded = (liborRate) => {
    this.setState({ current_libor_rate: liborRate.data["libor_rate"] });
  };

  componentDidMount = () => {
    this.loadCurrentLiborRate();
  };

  onError = (error) => console.log(error);

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidate = () => {
    const {
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
    } = this.state;

    let requiredRule = {
      required: true,
    };

    let isValid3 = this.doValidateImpl(
      interest_rate,
      requiredRule,
      "interest_rate_error",
      "Please enter the Interest Rate."
    );
    let isValid4 = this.doValidateImpl(
      late_interest_rate,
      requiredRule,
      "late_interest_rate_error",
      "Please enter the Late Interest Rate."
    );
    let isValid5 = this.doValidateImpl(
      initial_payment_percentage,
      requiredRule,
      "initial_payment_percentage_error",
      "Please enter the Inital Payment Percentage."
    );
    let isValid6 = this.doValidateImpl(
      repayment_months,
      requiredRule,
      "repayment_months_error",
      "Please enter the Repayment Months."
    );
    return isValid3 && isValid4 && isValid5 && isValid6;
  };

  doSubmit = (onAfterSave) => {
    if (!this.doValidate()) return;

    const {
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
    } = this.state;

    onAfterSave({
      interest_rate: interest_rate,
      late_interest_rate: late_interest_rate,
      initial_payment_percentage: initial_payment_percentage,
      repayment_months: repayment_months,
    });
  };
  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;

    const {
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
      current_libor_rate,
      interest_rate_error,
      late_interest_rate_error,
      initial_payment_percentage_error,
      repayment_months_error,
    } = this.state;

    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Extend Loan"}
        />
        <Modal.Content>
          <p>
            You are about to extend this loan. This action is irreversible. Are
            you sure?
          </p>
          <Form id={"description-form"} size="large">
            <Form.Input
              label={"Interest Rate"}
              name={"interest_rate"}
              value={interest_rate}
              type={"number"}
              step={"0.1"}
              error={interest_rate_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Late Interest Rate"}
              name={"late_interest_rate"}
              value={late_interest_rate}
              type={"number"}
              step={"0.1"}
              error={late_interest_rate_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Current Libor Rate"}
              name={"current_libor_rate"}
              value={current_libor_rate}
              readOnly
            />
            <Form.Input
              label={"Initial Payment Percentage"}
              name={"initial_payment_percentage"}
              value={initial_payment_percentage}
              type={"number"}
              step={"0.1"}
              error={initial_payment_percentage_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Repayment Months"}
              name={"repayment_months"}
              value={repayment_months}
              type={"number"}
              step={"1"}
              error={repayment_months_error}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={"noBtn"} color="red" inverted onClick={onCancelClick}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => this.doSubmit(onYesClick)}
          >
            <Icon name="checkmark" /> Extend Loan
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

ExtendLoanDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default ExtendLoanDialog;
