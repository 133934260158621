import React, { Component, Fragment } from "react";
import { Label, Segment, Table, Form, Button, Grid } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { LookupLiborRateService } from "../../services/lookup-service";
import { MessageDialog } from "../../components/message-dialog";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import DataLoader from "../../components/data-loader";
import { StringUtils } from "../../components/strings";

import { withTranslation } from "react-i18next";

class LookupLiborRatesPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    lookupLiborRates: [],
    isLookupLiborRatesLoading: true,
    currentLookupLiborRate: 0,
    confirmationDialogOpen: false,
    confirmationDialogMessage: "",
    confirmationDialogDescription: "",
  };

  loadData = () =>
    LookupLiborRateService.get_lookup_libor_rates(
      this.onLookupLiborRatesListSuccess,
      this.onError
    );

  componentDidMount = () => this.loadData();

  onLookupLiborRatesListSuccess = (listItems) => {
    this.setState({
      lookupLiborRates: listItems.data,
      isLookupLiborRatesLoading: false,
      currentLookupLiborRate: listItems.data[0].libor_rate,
    });
  };

  onError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Libor Rates",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isLookupLiborRatesLoading: false,
      currentLookupLiborRate: 0,
    });
  };

  onMessageDialogOk = (e, { name }) => {
    const { isError } = this.state;
    this.setState({ dialogOpen: false });

    if (isError) this.props.history.push("/pages/dashboard");
  };

  newLoan = () => this.props.history.push("/pages/lookup-libor-rates/new");

  getRefTranslationKey = (input) => {
    return `button-${input}`;
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  updateLiborRate = () => {
    this.setState({
      confirmationDialogOpen: true,
      confirmationDialogMessage:
        "Are you sure you want to update the current Libor Rate?",
      confirmationDialogDescription: "This action cannot be undone.",
    });
  };

  doSaveLiborateRate = () => {
    const { currentLookupLiborRate } = this.state;
    LookupLiborRateService.new_current_lookup_libor_rate(
      currentLookupLiborRate,
      this.updateLiborRateSuccess,
      this.onError
    );
  };

  cancelSaveLiborateRate = () => {
    this.setState({
      confirmationDialogOpen: false,
      confirmationDialogMessage: "",
      confirmationDialogDescription: "",
    });
  };

  updateLiborRateSuccess = () => {
    this.cancelSaveLiborateRate();
    this.loadData();
  };
  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
      isLookupLiborRatesLoading,
      lookupLiborRates,
      currentLookupLiborRate,
      confirmationDialogOpen,
      confirmationDialogMessage,
      confirmationDialogDescription,
    } = this.state;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationDialogMessage}
          description={confirmationDialogDescription}
          onYesClick={this.doSaveLiborateRate}
          onNoClick={this.cancelSaveLiborateRate}
        />
        <SingleGridLayout>
          <Grid>
            <Grid.Column width={5}>
              <Segment>
                <Label color={"black"} attached={"top left"}>
                  Current Libor Rate
                </Label>
                <Form id={"libor-rate-form"} size="large">
                  <Form.Input
                    label={"Current Libor Rate"}
                    name={"currentLookupLiborRate"}
                    value={currentLookupLiborRate}
                    type={"number"}
                    step={1}
                    onChange={this.handleChange}
                  />
                </Form>
                <Segment basic textAlign={"right"}>
                  <Button color={"blue"} onClick={this.updateLiborRate}>
                    Update
                  </Button>
                </Segment>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              <Fragment>
                <DataLoader
                  isLoading={isLookupLiborRatesLoading}
                  itemsLength={lookupLiborRates.length}
                  notFoundMessage={"There are No Libor Rates."}
                />
                {!isLookupLiborRatesLoading && lookupLiborRates.length > 0 && (
                  <Segment>
                    <Label color={"black"} attached={"top left"}>
                      Libor Rates History (Last 20 Only)
                    </Label>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Libor Rate</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Updated By</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {lookupLiborRates.map((liborRate) => (
                          <Table.Row key={liborRate.id}>
                            <Table.Cell>{liborRate.libor_rate}</Table.Cell>
                            <Table.Cell>
                              {StringUtils.toLocaleDateString(
                                liborRate.inserted_at
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {StringUtils.emailToName(liborRate.email)}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Segment>
                )}
              </Fragment>
            </Grid.Column>
          </Grid>
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(LookupLiborRatesPage);
