import React, { Component, Fragment } from "react";
import { Segment, Label, Form, Grid, Step, Button } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import {
  LookupLiborRateService,
  SecurityCodeInfoService,
} from "../../services/lookup-service";
import { LoanService } from "../../services/loan-service";
import { MessageDialog } from "../../components/message-dialog";
import approve from "approvejs";
import {
  AccountSelector,
  LendingValueSelector,
} from "./components/account-selectors";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";

class LoanNewPage extends Component {
  state = {
    step: 1,
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
  };

  loan = {
    interest_rate: 12,
    late_interest_rate: 12,
    initial_payment_percentage: 2,
    repayment_months: 12,
    start_date: format(new Date(), "yyyy-MM-dd"),
    current_libor_rate: 0,
    interest: 0,
    late_interest: 0,
    loan_status: "active",
    is_active: true,
    user_id: null,
  };

  pledge = {
    security_code: "",
    price: 0,
    pledge_value: 0.0,
    alert_percentage: 7.0,
    margin_percentage: 10.0,
    volume: 0,
    native_price: 0.0,
    volume_trigger: 0,
    native_currency: "USD",
    currency_trigger: 0.0,
  };

  loadCurrentLiborRate = () =>
    LookupLiborRateService.get_current_lookup_libor_rate(
      this.onLiborRateLoaded,
      this.onError
    );

  onLiborRateLoaded = (liborRate) => {
    this.loan["current_libor_rate"] = liborRate.data["libor_rate"];
    this.setState({ loan: this.loan });
  };

  componentDidMount = () => {
    this.loadCurrentLiborRate();
    this.setState({ pledge: this.pledge });
  };

  onError = (error) => console.log(error);

  handleChangeOnLoan = (e, { name, value }) => {
    this.loan[name] = value;
    this.setState({ loan: this.loan });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidateLoan = () => {
    const {
      account_id,
      principal,
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
      lending_value,
      start_date,
    } = this.loan;

    let requiredRule = {
      required: true,
    };

    let isValid1 = this.doValidateImpl(
      account_id,
      requiredRule,
      "account_id_error",
      "Please select the Account."
    );
    let isValid2 = this.doValidateImpl(
      principal,
      requiredRule,
      "principal_error",
      "Please enter the Principal."
    );
    let isValid3 = this.doValidateImpl(
      interest_rate,
      requiredRule,
      "interest_rate_error",
      "Please enter the Interest Rate."
    );
    let isValid4 = this.doValidateImpl(
      late_interest_rate,
      requiredRule,
      "late_interest_rate_error",
      "Please enter the Late Interest Rate."
    );
    let isValid5 = this.doValidateImpl(
      initial_payment_percentage,
      requiredRule,
      "initial_payment_percentage_error",
      "Please enter the Inital Payment Percentage."
    );
    let isValid6 = this.doValidateImpl(
      repayment_months,
      requiredRule,
      "repayment_months_error",
      "Please enter the Repayment Months."
    );

    let isValid7 = this.doValidateImpl(
      lending_value,
      requiredRule,
      "lending_value_error",
      "Please select the Lending Value."
    );

    let isValid8 = this.doValidateImpl(
      start_date,
      requiredRule,
      "start_date_error",
      "Please set the Start Date."
    );

    return (
      isValid1 &&
      isValid2 &&
      isValid3 &&
      isValid4 &&
      isValid5 &&
      isValid6 &&
      isValid7 &&
      isValid8
    );
  };

  stepping = (delta) => {
    const { step } = this.state;
    this.setState({ step: step + delta });
    window.scroll(0, 0);
  };

  upStep = () => {
    const { step } = this.state;
    if (step === 1 && !this.doValidateLoan()) return;

    this.stepping(1);
  };
  downStep = () => this.stepping(-1);

  /* Pledge */
  lookupSecurityCode = () => {
    const { security_code } = this.pledge;

    SecurityCodeInfoService.get_security_code_info(
      security_code,
      this.onLookupSecurityCodeSuccess,
      this.onError
    );
  };

  onLookupSecurityCodeSuccess = (securityCodeInfo) => {
    if (securityCodeInfo.status === 200) {
      let info = securityCodeInfo.data;
      let price = info["price"];
      this.pledge["security_code"] = info["security_code"];
      this.pledge["price"] = price;
      this.pledge["native_price"] = price;

      const { principal, lending_value } = this.loan;

      this.pledge["volume"] = Math.ceil(
        (principal * 100) / lending_value / price
      );

      this.setState({ pledge: this.pledge });
    }
  };

  handleChangeOnPledge = (e, { name, value }) => {
    this.pledge[name] = value;
    this.setState({ pledge: this.pledge });
  };

  doValidatePledge = () => {
    const {
      security_code,
      price,
      alert_percentage,
      margin_percentage,
      volume,
      native_price,
      volume_trigger,
      native_currency,
      currency_trigger,
    } = this.pledge;

    let requiredRule = {
      required: true,
    };

    let isValid1 = this.doValidateImpl(
      security_code,
      requiredRule,
      "security_code_error",
      "Please enter the Security Code."
    );

    let isValid2 = this.doValidateImpl(
      price,
      requiredRule,
      "price_error",
      "Please enter or lookup the current Price of the Security Code."
    );
    let isValid4 = this.doValidateImpl(
      alert_percentage,
      requiredRule,
      "alert_percentage_error",
      "Please enter the Alert Percentage."
    );
    let isValid5 = this.doValidateImpl(
      margin_percentage,
      requiredRule,
      "margin_percentage_error",
      "Please enter the Margin Percentage."
    );
    let isValid6 = this.doValidateImpl(
      volume,
      requiredRule,
      "volume_error",
      "Please enter or calculate the Volume."
    );

    let isValid7 = this.doValidateImpl(
      native_price,
      requiredRule,
      "native_price_error",
      "Please enter or lookup the native Price of the Security Code."
    );

    let isValid8 = this.doValidateImpl(
      volume_trigger,
      requiredRule,
      "volume_trigger_error",
      "Please set the Volume Trigger."
    );

    let isValid9 = this.doValidateImpl(
      native_currency,
      requiredRule,
      "native_currency_error",
      "Please set the Native Currency."
    );

    let isValid10 = this.doValidateImpl(
      currency_trigger,
      requiredRule,
      "currency_trigger_error",
      "Please set the Currency Trigger."
    );
    return (
      isValid1 &&
      isValid2 &&
      isValid4 &&
      isValid5 &&
      isValid6 &&
      isValid7 &&
      isValid8 &&
      isValid9 &&
      isValid10
    );
  };

  checkPledgeValue = () => {
    const { principal, lending_value } = this.loan;
    const { volume, price } = this.pledge;
    let pledgeValue = (price * volume).toFixed(2);

    let message = `The Loan is HIGHER than ${lending_value}% of the Pledge Value.\n`;
    message = message + `The pledge value is ${pledgeValue} `;
    message = message + `while the loan is ${principal}`;

    this.setState({ dialogMessage: message });
    return pledgeValue >= (principal * 100) / lending_value;
  };

  onMessageDialogOk = (e, { name }) => this.setState({ dialogOpen: false });

  doSubmit = () => {
    const { step } = this.state;
    if (step !== 2) return;
    if (!this.doValidatePledge()) return;
    if (!this.checkPledgeValue()) {
      this.setState({
        dialogOpen: true,
        dialogTitle: "Invalid Pledge Value",
        dialogDescription: "",
        isError: true,
      });
      return;
    }
    LoanService.save_loan(
      this.loan,
      this.pledge,
      this.onLoanSaveSuccess,
      this.onError
    );
  };

  onLoanSaveSuccess = () => this.props.history.push("/pages/loans");

  render = () => {
    const {
      step,
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
    } = this.state;
    const {
      account_id,
      principal,
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
      lending_value,
      start_date,
      current_libor_rate,
    } = this.loan;
    const {
      account_id_error,
      principal_error,
      interest_rate_error,
      late_interest_rate_error,
      initial_payment_percentage_error,
      repayment_months_error,
      lending_value_error,
      start_date_error,
    } = this.state;

    const {
      security_code,
      price,
      alert_percentage,
      margin_percentage,
      volume,
      native_price,
      volume_trigger,
      native_currency,
      currency_trigger,
    } = this.pledge;

    const {
      security_code_error,
      price_error,
      alert_percentage_error,
      margin_percentage_error,
      volume_error,
      native_price_error,
      volume_trigger_error,
      native_currency_error,
      currency_trigger_error,
    } = this.state;

    const { t } = this.props;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />
        <SingleGridLayout>
          <Grid>
            <Grid.Column width={5}>
              <Step.Group ordered vertical fluid>
                <Step completed={step >= 2} disabled={step < 1}>
                  <Step.Content>
                    <Step.Title>Loan Details</Step.Title>
                  </Step.Content>
                </Step>
                <Step disabled={step < 2}>
                  <Step.Content>
                    <Step.Title>Pledge Details</Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Grid.Column>
            <Grid.Column width={11}>
              {step === 1 && (
                <Segment padded>
                  <Label color={"black"} attached={"top left"}>
                    Loan Details
                  </Label>
                  <Form id={"loan-details-form"} size="large">
                    <Form.Input
                      label={"Account"}
                      error={account_id_error}
                      control={() => (
                        <AccountSelector
                          t={t}
                          name={"account_id"}
                          value={account_id}
                          onChange={this.handleChangeOnLoan}
                        />
                      )}
                    />
                    <Form.Input
                      label={"Principal"}
                      name={"principal"}
                      value={principal}
                      type={"number"}
                      step={50000}
                      error={principal_error}
                      onChange={this.handleChangeOnLoan}
                    />
                    <Form.Input
                      label={"Interest Rate"}
                      name={"interest_rate"}
                      value={interest_rate}
                      type={"number"}
                      step={"0.1"}
                      error={interest_rate_error}
                      onChange={this.handleChangeOnLoan}
                    />
                    <Form.Input
                      label={"Late Interest Rate"}
                      name={"late_interest_rate"}
                      value={late_interest_rate}
                      type={"number"}
                      step={"0.1"}
                      error={late_interest_rate_error}
                      onChange={this.handleChangeOnLoan}
                    />
                    <Form.Input
                      label={"Current Libor Rate"}
                      name={"current_libor_rate"}
                      value={current_libor_rate}
                      readeOnly
                    />
                    <Form.Input
                      label={"Initial Payment Percentage"}
                      name={"initial_payment_percentage"}
                      value={initial_payment_percentage}
                      type={"number"}
                      step={"0.1"}
                      error={initial_payment_percentage_error}
                      onChange={this.handleChangeOnLoan}
                    />
                    <Form.Input
                      label={"Repayment Months"}
                      name={"repayment_months"}
                      value={repayment_months}
                      type={"number"}
                      step={"1"}
                      error={repayment_months_error}
                      onChange={this.handleChangeOnLoan}
                    />
                    <Form.Input
                      label={"Lending Value"}
                      error={lending_value_error}
                      control={() => (
                        <LendingValueSelector
                          t={t}
                          name={"lending_value"}
                          value={lending_value}
                          onChange={this.handleChangeOnLoan}
                        />
                      )}
                    />
                    <Form.Input
                      label={"Start Date"}
                      name={"start_date"}
                      value={start_date}
                      type={"date"}
                      onChange={this.handleChangeOnLoan}
                      error={start_date_error}
                    />
                  </Form>
                </Segment>
              )}
              {step === 2 && (
                <Segment padded>
                  <Label color={"black"} attached={"top left"}>
                    Pledge Details
                  </Label>
                  <Form id={"pledge-details-form"} size="large">
                    <Form.Group>
                      <Form.Input
                        label={"Security Code"}
                        name={"security_code"}
                        value={security_code}
                        error={security_code_error}
                        onChange={this.handleChangeOnPledge}
                        width={13}
                      />
                      <Form.Button
                        width={3}
                        floated={"right"}
                        color={"blue"}
                        onClick={this.lookupSecurityCode}
                        basic
                      >
                        Lookup
                      </Form.Button>
                    </Form.Group>
                    <Form.Input
                      label={"Volume"}
                      name={"volume"}
                      value={volume}
                      type={"number"}
                      error={volume_error}
                      onChange={this.handleChangeOnPledge}
                    />
                    <Form.Input
                      label={"Price in USD"}
                      name={"price"}
                      value={price}
                      type={"number"}
                      error={price_error}
                      onChange={this.handleChangeOnPledge}
                    />
                    <Form.Input
                      label={"Native Currency Code"}
                      name={"native_currency"}
                      value={native_currency}
                      error={native_currency_error}
                      onChange={this.handleChangeOnPledge}
                    />
                    <Form.Input
                      label={"Price in Native Currency"}
                      name={"native_price"}
                      value={native_price}
                      type={"number"}
                      error={native_price_error}
                      onChange={this.handleChangeOnPledge}
                    />
                    <Form.Input
                      label={"Alert Price in Native Currency"}
                      name={"currency_trigger"}
                      value={currency_trigger}
                      error={currency_trigger_error}
                      onChange={this.handleChangeOnPledge}
                    />
                    <Form.Input
                      label={"Volume Alert"}
                      name={"volume_trigger"}
                      value={volume_trigger}
                      error={volume_trigger_error}
                      onChange={this.handleChangeOnPledge}
                    />

                    <Form.Input
                      label={"Alert Percentage"}
                      name={"alert_percentage"}
                      value={alert_percentage}
                      error={alert_percentage_error}
                      onChange={this.handleChangeOnPledge}
                    />

                    <Form.Input
                      label={"Margin Percentage"}
                      name={"margin_percentage"}
                      value={margin_percentage}
                      error={margin_percentage_error}
                      onChange={this.handleChangeOnPledge}
                    />
                  </Form>
                </Segment>
              )}

              {step !== 2 && (
                <Segment clearing>
                  {step !== 1 && (
                    <Button color={"red"} onClick={this.downStep}>
                      {t("button-back")}
                    </Button>
                  )}
                  <Button
                    floated={"right"}
                    color={"black"}
                    onClick={this.upStep}
                  >
                    {t("button-next")}
                  </Button>
                </Segment>
              )}

              {step === 2 && (
                <Segment padded>
                  <Button color={"red"} onClick={this.downStep}>
                    {t("button-back")}
                  </Button>
                  <Button
                    floated={"right"}
                    color={"blue"}
                    onClick={this.doSubmit}
                  >
                    {t("button-submit")}
                  </Button>
                </Segment>
              )}
            </Grid.Column>
          </Grid>
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(LoanNewPage);
