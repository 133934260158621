import React from "react";
import { Select } from "semantic-ui-react";
import { AccountService } from "../../../services/account-service";

export const AccountSelector = ({ name, value, onChange }) => {
  const [accounts, setAccounts] = React.useState([]);

  React.useEffect(() => {
    const onError = (error) => console.log(error);

    const onActiveAccountsListSuccess = (listItems) => {
      let accountsList = [];
      for (let account of listItems.data) {
        accountsList.push({
          key: account.id,
          value: account.id,
          text: account.name,
        });
      }
      setAccounts(accountsList);
    };

    const loadData = () => {
      AccountService.get_active_basic_accounts(
        onActiveAccountsListSuccess,
        onError
      );
    };

    loadData();
  }, []);

  return (
    <Select
      placeholder={"Choose Account"}
      options={accounts}
      name={name}
      value={value}
      onChange={onChange}
      search
    />
  );
};

export const LendingValueSelector = ({ name, value, onChange }) => {
  const lendingValues = [
    { key: "30", value: 30, text: "30%" },
    { key: "40", value: 40, text: "40%" },
    { key: "50", value: 50, text: "50%" },
  ];

  return (
    <Select
      placeholder={"Choose Lending Value"}
      options={lendingValues}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export const UserTypeSelector = ({ t, name, value, onChange }) => {
  const userTypes = [
    {
      key: "borrower",
      value: "borrower",
      text: t("selector-borrower_referrer-borrower"),
    },
    {
      key: "referrer",
      value: "referrer",
      text: t("selector-borrower_referrer-referrer"),
    },
  ];
  return (
    <Select
      placeholder={t("selector-borrower_referrer")}
      options={userTypes}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export const YesNoSelector = ({ t, name, value, onChange, placeholder }) => {
  const options = [
    { key: "yes", value: "yes", text: t("button-yes") },
    { key: "no", value: "no", text: t("button-no") },
  ];
  return (
    <Select
      placeholder={placeholder}
      options={options}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};
