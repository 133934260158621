import React, { Component, Fragment } from "react";
import { Icon, Label, Menu, Segment, Table } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { LoanService } from "../../services/loan-service";
import { MessageDialog } from "../../components/message-dialog";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import DataLoader from "../../components/data-loader";
import { StringUtils } from "../../components/strings";

import { withTranslation } from "react-i18next";

class LoansPage extends Component {
  state = {
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    activeLoans: [],
    isActiveLoansLoading: true,
    inactiveLoans: [],
    isInactiveLoansLoading: true,
    activeItem: "active",
  };

  /* Menu Item Click */
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  loadData = () => {
    LoanService.get_loans(true, this.onActiveLoansListSuccess, this.onError);
    LoanService.get_loans(
      false,
      this.onInactiveLoansListSuccess,
      this.onInactiveError
    );
  };

  componentDidMount = () => this.loadData();

  onActiveLoansListSuccess = (listItems) => {
    this.setState({ activeLoans: listItems.data, isActiveLoansLoading: false });
  };

  onInactiveLoansListSuccess = (listItems) => {
    this.setState({
      inactiveLoans: listItems.data,
      isInactiveLoansLoading: false,
    });
  };

  onError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Loans",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isActiveLoansLoading: false,
    });
  };

  onInactiveError = (error) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Loans",
      dialogMessage: this.props.t("general-error-retrieving-records"),
      dialogDescription: error.message,
      isError: true,
      isInactiveLoansLoading: false,
    });
  };

  onMessageDialogOk = (e, { name }) => {
    const { isError } = this.state;
    this.setState({ dialogOpen: false });

    if (isError) this.props.history.push("/pages/dashboard");
  };

  viewLoan = (loanId) => this.props.history.push(`/pages/loans/${loanId}`);

  newLoan = () => this.props.history.push("/pages/loans/new");

  archiveLoan = (loanId) => {
    this.setState({ confirmationDialogOpen: true, archiveLoanId: loanId });
  };

  onConfirmationDialogYesClick = () => {
    const { archiveLoanId } = this.state;
    LoanService.archive_loan(archiveLoanId, this.loanArchived, this.onError);
  };
  loanArchived = () => {
    this.setState({ confirmationDialogOpen: false, archiveLoanId: null });
    this.loadData();
  };
  onConfirmationDialogNoClick = () =>
    this.setState({ confirmationDialogOpen: false, archiveLoanId: null });

  getRefTranslationKey = (input) => {
    return `button-${input}`;
  };

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
      isActiveLoansLoading,
      activeLoans,
      isInactiveLoansLoading,
      inactiveLoans,
      activeItem,
      confirmationDialogOpen,
    } = this.state;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />

        <ConfirmationDialog
          open={confirmationDialogOpen}
          message={"Are you sure you want to Archive this Loan?"}
          onYesClick={this.onConfirmationDialogYesClick}
          onNoClick={this.onConfirmationDialogNoClick}
        />

        <SingleGridLayout>
          <Segment basic textAlign={"right"}>
            <Icon
              name={"plus"}
              color={"blue"}
              bordered
              inverted
              onClick={this.newLoan}
            />
          </Segment>

          {
            <Menu pointing secondary>
              <Menu.Item
                name="active"
                active={activeItem === "active"}
                onClick={this.handleItemClick}
              >
                Active Loans
              </Menu.Item>

              <Menu.Item
                name="inactive"
                active={activeItem === "inactive"}
                onClick={this.handleItemClick}
              >
                Archived Loans
              </Menu.Item>
            </Menu>
          }

          {activeItem === "active" && (
            <Fragment>
              <DataLoader
                isLoading={isActiveLoansLoading}
                itemsLength={activeLoans.length}
                notFoundMessage={"There are No Active Loans."}
              />
              {!isActiveLoansLoading && activeLoans.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Active Loans
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell>Principal</Table.HeaderCell>
                        <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                        <Table.HeaderCell>
                          Initial Payment Percentage
                        </Table.HeaderCell>
                        <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                        <Table.HeaderCell>Approved By</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {activeLoans.map((loan) => (
                        <Table.Row key={loan.id}>
                          <Table.Cell>{loan.account_name}</Table.Cell>
                          <Table.Cell>{loan.principal}</Table.Cell>
                          <Table.Cell>{loan.interest_rate}</Table.Cell>
                          <Table.Cell>
                            {loan.initial_payment_percentage}
                          </Table.Cell>
                          <Table.Cell>{loan.repayment_months}</Table.Cell>
                          <Table.Cell>
                            {StringUtils.emailToName(loan.approved_by)}
                          </Table.Cell>
                          <Table.Cell>
                            {StringUtils.capitalize(loan.loan_status)}
                          </Table.Cell>
                          <Table.Cell>
                            <Icon
                              name={"edit"}
                              color="blue"
                              onClick={() => this.viewLoan(loan.id)}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {loan.loan_status !== "active" && (
                              <Icon
                                name={"minus"}
                                color="red"
                                onClick={() => this.archiveLoan(loan.id)}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}

          {activeItem === "inactive" && (
            <Fragment>
              <DataLoader
                isLoading={isInactiveLoansLoading}
                itemsLength={activeLoans.length}
                notFoundMessage={"There are No Active Loans."}
              />
              {!isInactiveLoansLoading && inactiveLoans.length > 0 && (
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Archived Loans
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Account</Table.HeaderCell>
                        <Table.HeaderCell>Principal</Table.HeaderCell>
                        <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                        <Table.HeaderCell>
                          Initial Payment Percentage
                        </Table.HeaderCell>
                        <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                        <Table.HeaderCell>Approved By</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {inactiveLoans.map((loan) => (
                        <Table.Row key={loan.id}>
                          <Table.Cell>{loan.account_name}</Table.Cell>
                          <Table.Cell>{loan.principal}</Table.Cell>
                          <Table.Cell>{loan.interest_rate}</Table.Cell>
                          <Table.Cell>
                            {loan.initial_payment_percentage}
                          </Table.Cell>
                          <Table.Cell>{loan.repayment_months}</Table.Cell>
                          <Table.Cell>
                            {StringUtils.emailToName(loan.approved_by)}
                          </Table.Cell>
                          <Table.Cell>
                            {StringUtils.capitalize(loan.loan_status)}
                          </Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(LoansPage);
