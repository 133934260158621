import axios from "axios";

export const UserService = {
  login: (email, password, onSuccess, onError) => {
    axios
      .post("/api/auth/jwt/login", `username=${email}&password=${password}`)
      .then(onSuccess)
      .catch(onError);
  },

  changePassword: (password, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(
        "/api/auth/change-password",
        { password: password },
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },

  resetPassword: (email, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(
        "/api/auth/forgot-password",
        { email: email },
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },

  currentUser: (onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get("/api/users/me", { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },

  logout: (onSuccess) => {
    sessionStorage.removeItem("token");
    onSuccess();
  },
};
