import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import approve from "approvejs";

class LogActivityDialog extends Component {
  state = {
    log_container: {},
  };

  onError = (error) => console.log(error);

  handleChange = (e, { name, value }) => {
    const { log_container } = this.state;
    log_container[name] = value;
    this.setState({ log_container: log_container });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidate = () => {
    const { log_container } = this.state;
    const { activity } = log_container;

    let requiredRule = {
      required: true,
    };

    let isValid3 = this.doValidateImpl(
      activity,
      requiredRule,
      "activity_error",
      "Please enter the Activity."
    );
    return isValid3;
  };

  doSubmit = (onAfterSave) => {
    if (!this.doValidate()) return;

    const { log_container } = this.state;
    this.setState({ log_container: {} });

    onAfterSave(log_container);
  };
  doCancel = (onAfterCancel) => {
    this.setState({ log_container: {} });
    onAfterCancel();
  };
  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;

    const { log_container, activity_error } = this.state;
    const { activity } = log_container;
    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Log Activity"}
        />
        <Modal.Content>
          <p>Please key in the activity to log</p>
          <Form id={"description-form"} size="large">
            <Form.Input
              label={"Activity"}
              name={"activity"}
              value={activity}
              error={activity_error}
              placeholder={"What did you do?"}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name={"noBtn"}
            color="red"
            inverted
            onClick={() => this.doCancel(onCancelClick)}
          >
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => this.doSubmit(onYesClick)}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

LogActivityDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default LogActivityDialog;
