import axios from "axios";

export const LoanAlertService = {
  get_loan_alerts_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans/${loan_id}/alerts`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
};
