import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

class CloseLoanDialog extends Component {
  state = {
    defaulted: false,
  };

  handleChange = (e, { name, value }) => {
    const { defaulted } = this.state;
    this.setState({ defaulted: !defaulted });
  };

  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;
    const { defaulted } = this.state;
    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Close Loan"}
        />
        <Modal.Content>
          <p>
            You are about to close this loan. You will not be able to make
            furter changes to this loan. Are you sure?
          </p>
          <Form id={"description-form"} size="large">
            <Form.Checkbox
              name={"defaulted"}
              label={"Borrower has DEFAULTED on Loan."}
              checked={defaulted}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={"noBtn"} color="red" inverted onClick={onCancelClick}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => onYesClick(defaulted)}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

CloseLoanDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default CloseLoanDialog;
