import axios from "axios";

export const LoanRepaymentService = {
  get_loan_repayments_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans/${loan_id}/repayments`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  has_late_loan_repayments_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans/${loan_id}/has-late-repayments`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  flag_loan_repayment_as_late: (loanRepaymentId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(
        `/api/loan-repayments/${loanRepaymentId}/late`,
        {},
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },
  mark_loan_repayment_as_paid: (loanRepaymentId, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .put(
        `/api/loan-repayments/${loanRepaymentId}/paid`,
        {},
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },
};
