import React, { Component } from "react";
import { Segment, Label, Icon, Table } from "semantic-ui-react";
import SingleGridLayout from "../layout/single-grid";
import { withTranslation } from "react-i18next";
import { LoanService } from "../services/loan-service";
import { StringUtils } from "../components/strings";

class DashboardPage extends Component {
  state = {
    overdueLoans: [],
    overdueLoansLoading: true,
    marginTriggeredLoans: [],
    marginTriggeredLoansLoading: true,
    alertTriggeredLoans: [],
    alertTriggeredLoansLoading: true,
    volumeTriggeredLoans: [],
    volumeTriggeredLoansLoading: true,
    currencyTriggeredLoans: [],
    currencyTriggeredLoansLoading: true,
  };

  loadData = () => {
    LoanService.get_overdue_loans(this.onOverdueListSuccess, this.onError);
    LoanService.get_margin_triggered_loans(
      this.onMarginTriggeredListSuccess,
      this.onError
    );
    LoanService.get_alert_triggered_loans(
      this.onAlertTriggeredListSuccess,
      this.onError
    );
    LoanService.get_volume_triggered_loans(
      this.onVolumeTriggeredListSuccess,
      this.onError
    );
    LoanService.get_currency_triggered_loans(
      this.onCurrencyTriggeredListSuccess,
      this.onError
    );
  };

  onOverdueListSuccess = (listItems) =>
    this.setState({ overdueLoans: listItems.data, overdueLoansLoading: false });
  onMarginTriggeredListSuccess = (listItems) =>
    this.setState({
      marginTriggeredLoans: listItems.data,
      marginTriggeredLoansLoading: false,
    });
  onAlertTriggeredListSuccess = (listItems) =>
    this.setState({
      alertTriggeredLoans: listItems.data,
      alertTriggeredLoansLoading: false,
    });
  onVolumeTriggeredListSuccess = (listItems) =>
    this.setState({
      volumeTriggeredLoans: listItems.data,
      volumeTriggeredLoansLoading: false,
    });
  onCurrencyTriggeredListSuccess = (listItems) =>
    this.setState({
      currencyTriggeredLoans: listItems.data,
      currencyTriggeredLoansLoading: false,
    });

  onError = (error) => console.log(error);

  componentDidMount = () => this.loadData();

  viewLoan = (loan_id) => this.props.history.push(`/pages/loans/${loan_id}`);

  render = () => {
    const { overdueLoans } = this.state;
    const { marginTriggeredLoans } = this.state;
    const { alertTriggeredLoans } = this.state;
    const { volumeTriggeredLoans } = this.state;
    const { currencyTriggeredLoans } = this.state;
    return (
      <SingleGridLayout>
        <Segment padded>
          <Label attached={"top left"} color={"black"}>
            Loans with Overdue Interests
          </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                <Table.HeaderCell>Initial Payment Percentage</Table.HeaderCell>
                <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {overdueLoans.map((loan) => (
                <Table.Row key={loan.id}>
                  <Table.Cell>{loan.account_name}</Table.Cell>
                  <Table.Cell>{loan.principal}</Table.Cell>
                  <Table.Cell>{loan.interest_rate}</Table.Cell>
                  <Table.Cell>{loan.initial_payment_percentage}</Table.Cell>
                  <Table.Cell>{loan.repayment_months}</Table.Cell>
                  <Table.Cell>
                    {StringUtils.emailToName(loan.approved_by)}
                  </Table.Cell>
                  <Table.Cell>
                    {StringUtils.capitalize(loan.loan_status)}
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      name={"edit"}
                      color="blue"
                      onClick={() => this.viewLoan(loan.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <Segment padded>
          <Label attached={"top left"} color={"blue"}>
            Loans with Margin Triggered
          </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                <Table.HeaderCell>Initial Payment Percentage</Table.HeaderCell>
                <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {marginTriggeredLoans.map((loan) => (
                <Table.Row key={loan.id}>
                  <Table.Cell>{loan.account_name}</Table.Cell>
                  <Table.Cell>{loan.principal}</Table.Cell>
                  <Table.Cell>{loan.interest_rate}</Table.Cell>
                  <Table.Cell>{loan.initial_payment_percentage}</Table.Cell>
                  <Table.Cell>{loan.repayment_months}</Table.Cell>
                  <Table.Cell>
                    {StringUtils.emailToName(loan.approved_by)}
                  </Table.Cell>
                  <Table.Cell>{loan.loan_status}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name={"edit"}
                      color="blue"
                      onClick={() => this.viewLoan(loan.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <Segment padded>
          <Label attached={"top left"} color={"black"}>
            Loans with Alert Triggered
          </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                <Table.HeaderCell>Initial Payment Percentage</Table.HeaderCell>
                <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {alertTriggeredLoans.map((loan) => (
                <Table.Row key={loan.id}>
                  <Table.Cell>{loan.account_name}</Table.Cell>
                  <Table.Cell>{loan.principal}</Table.Cell>
                  <Table.Cell>{loan.interest_rate}</Table.Cell>
                  <Table.Cell>{loan.initial_payment_percentage}</Table.Cell>
                  <Table.Cell>{loan.repayment_months}</Table.Cell>
                  <Table.Cell>
                    {StringUtils.emailToName(loan.approved_by)}
                  </Table.Cell>
                  <Table.Cell>{loan.loan_status}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name={"edit"}
                      color="blue"
                      onClick={() => this.viewLoan(loan.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <Segment padded>
          <Label attached={"top left"} color={"blue"}>
            Loans with Volume Triggered
          </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                <Table.HeaderCell>Initial Payment Percentage</Table.HeaderCell>
                <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {volumeTriggeredLoans.map((loan) => (
                <Table.Row key={loan.id}>
                  <Table.Cell>{loan.account_name}</Table.Cell>
                  <Table.Cell>{loan.principal}</Table.Cell>
                  <Table.Cell>{loan.interest_rate}</Table.Cell>
                  <Table.Cell>{loan.initial_payment_percentage}</Table.Cell>
                  <Table.Cell>{loan.repayment_months}</Table.Cell>
                  <Table.Cell>
                    {StringUtils.emailToName(loan.approved_by)}
                  </Table.Cell>
                  <Table.Cell>{loan.loan_status}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name={"edit"}
                      color="blue"
                      onClick={() => this.viewLoan(loan.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <Segment padded>
          <Label attached={"top left"} color={"black"}>
            Loans with Currency Triggered
          </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
                <Table.HeaderCell>Initial Payment Percentage</Table.HeaderCell>
                <Table.HeaderCell>Repayment Months</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {currencyTriggeredLoans.map((loan) => (
                <Table.Row key={loan.id}>
                  <Table.Cell>{loan.account_name}</Table.Cell>
                  <Table.Cell>{loan.principal}</Table.Cell>
                  <Table.Cell>{loan.interest_rate}</Table.Cell>
                  <Table.Cell>{loan.initial_payment_percentage}</Table.Cell>
                  <Table.Cell>{loan.repayment_months}</Table.Cell>
                  <Table.Cell>
                    {StringUtils.emailToName(loan.approved_by)}
                  </Table.Cell>
                  <Table.Cell>{loan.loan_status}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name={"edit"}
                      color="blue"
                      onClick={() => this.viewLoan(loan.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </SingleGridLayout>
    );
  };
}

export default withTranslation("translations")(DashboardPage);
