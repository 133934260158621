import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import approve from "approvejs";

class AccountFormDialog extends Component {
  state = {
    account: {
      name: "",
      contact: "",
      email: "",
    },
  };

  descriptionData = {};

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.account !== prevProps.account)
      this.setState({
        account: this.props.account,
      });
  };

  onError = (error) => console.log(error);

  handleChange = (e, { name, value }) => {
    const { account } = this.state;
    account[name] = value;
    this.setState({ account: account });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidate = () => {
    const { account } = this.state;
    const { name, contact, email } = account;

    let requiredRule = {
      required: true,
    };

    let isValid3 = this.doValidateImpl(
      name,
      requiredRule,
      "name_error",
      "Please enter the Name."
    );
    let isValid4 = this.doValidateImpl(
      contact,
      requiredRule,
      "contact_error",
      "Please enter the Contact."
    );
    let isValid5 = this.doValidateImpl(
      email,
      requiredRule,
      "email_error",
      "Please enter the Email."
    );
    return isValid3 && isValid4 && isValid5;
  };

  doSubmit = (onAfterSave) => {
    if (!this.doValidate()) return;

    const { account } = this.state;

    onAfterSave(account);
  };
  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;

    const { account, name_error, contact_error, email_error } = this.state;
    const { name, contact, email } = account;
    return (
      <Modal open={open}>
        <Header color={"Black"} content={"Account"} />
        <Modal.Content>
          <Form id={"description-form"} size="large">
            <Form.Input
              label={"Name"}
              name={"name"}
              value={name}
              error={name_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Contact"}
              name={"contact"}
              value={contact}
              error={contact_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Email"}
              name={"email"}
              value={email}
              error={email_error}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button name={"noBtn"} color="red" inverted onClick={onCancelClick}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => this.doSubmit(onYesClick)}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

AccountFormDialog.propTypes = {
  open: PropTypes.bool,
  account: PropTypes.object,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default AccountFormDialog;
