import React, { Component } from "react";
import { Modal, Header, Button, Icon, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import approve from "approvejs";

class AddPledgeDialog extends Component {
  state = {
    pledge: {},
  };

  onError = (error) => console.log(error);

  handleChange = (e, { name, value }) => {
    const { pledge } = this.state;
    pledge[name] = value;
    this.setState({ pledge: pledge });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidate = () => {
    const { pledge } = this.state;
    const { volume, price, native_price } = pledge;

    let requiredRule = {
      required: true,
    };

    let isValid3 = this.doValidateImpl(
      volume,
      requiredRule,
      "volume_error",
      "Please enter the Volume."
    );
    let isValid4 = this.doValidateImpl(
      price,
      requiredRule,
      "price_error",
      "Please enter the Price."
    );
    let isValid6 = this.doValidateImpl(
      native_price,
      requiredRule,
      "native_price_error",
      "Please enter the Native Price."
    );
    return isValid3 && isValid4 && isValid6;
  };

  doSubmit = (onAfterSave) => {
    if (!this.doValidate()) return;

    const { pledge } = this.state;

    onAfterSave(pledge);
  };

  doCancel = (onAfterCancel) => {
    this.setState({ pledge: {} });
    onAfterCancel();
  };
  render = () => {
    const { open, onYesClick, onCancelClick } = this.props;

    const { pledge, volume_error, price_error, native_price_error } =
      this.state;
    const { volume, price, native_price } = pledge;
    return (
      <Modal open={open}>
        <Header
          icon={"question circle outline"}
          color={"red"}
          content={"Change Alerts"}
        />
        <Modal.Content>
          <p>Please key in the volume and current price to top up.</p>
          <Form id={"description-form"} size="large">
            <Form.Input
              label={"Volume"}
              name={"volume"}
              value={volume}
              type={"number"}
              step={"50000"}
              error={volume_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Price"}
              name={"price"}
              value={price}
              type={"number"}
              step={"0.1"}
              error={price_error}
              onChange={this.handleChange}
            />
            <Form.Input
              label={"Native Price"}
              name={"native_price"}
              value={native_price}
              type={"number"}
              step={"0.1"}
              error={native_price_error}
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name={"noBtn"}
            color="red"
            inverted
            onClick={() => this.doCancel(onCancelClick)}
          >
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            name={"yesBtn"}
            color="green"
            inverted
            onClick={() => this.doSubmit(onYesClick)}
          >
            <Icon name="checkmark" /> Add Pledge
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

AddPledgeDialog.propTypes = {
  open: PropTypes.bool,
  onYesClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default AddPledgeDialog;
