import React, { Component, Fragment } from "react";
import SingleGridLayout from "../../layout/single-grid";
import {
  Label,
  Segment,
  Table,
  Grid,
  Message,
  Button,
  Icon,
} from "semantic-ui-react";
import { LoanService } from "../../services/loan-service";
import { PledgeService } from "../../services/pledge-service";
import { LoanRepaymentService } from "../../services/loan-repayment-service";
import { LoanActivityLogService } from "../../services/loan-activity-log-service";
import { LoanAlertService } from "../../services/loan-alert-service";
import { MessageDialog } from "../../components/message-dialog";
import DataLoader from "../../components/data-loader";
import { StringUtils } from "../../components/strings";
import CloseLoanDialog from "./components/close-loan-dialog";
import ExtendLoanDialog from "./components/extend-loan-dialog";
import RepaymentDialog from "./components/repayment-dialog";
import ChangeAlertsDialog from "./components/change-alerts-dialog";
import AddPledgeDialog from "./components/add-pledge-dialog";
import LogActivityDialog from "./components/log-activity-dialog";

import { withTranslation } from "react-i18next";

class LoanViewPage extends Component {
  state = {
    fileViewerFileUrl: "",
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
    loan: {},
    loanAlerts: [],
    lastLoanAlert: null,
    volumeTriggered: false,
    pledge: {},
    pledgeAdditions: [],
    repayments: [],
    hasLateRepayments: false,
    activityLogs: [],
    isLoading: true,
    closeDialogOpen: false,
    closeLoanId: null,
    extendLoanDialogOpen: false,
    extendLoanId: null,
    repaymentDialogOpen: false,
    repayment: {},
    changeAlertDialogOpen: false,
    changeAlertPledge: null,
    addPledgeDialogOpen: false,
    logActivityDialogOpen: false,
  };

  loadData = () => {
    const { loan_id } = this.props.match.params;
    LoanService.get_loan(loan_id, this.onGetLoanSuccess, this.onError);
    LoanAlertService.get_loan_alerts_by_loan_id(
      loan_id,
      this.onGetLoanAlertsSuccess,
      this.onError
    );
    PledgeService.get_pledge_by_loan_id(
      loan_id,
      this.onGetPledgeSuccess,
      this.onError
    );
    PledgeService.get_pledge_additions_by_loan_id(
      loan_id,
      this.onGetPledgeAdditionsSuccess,
      this.onError
    );
    LoanRepaymentService.get_loan_repayments_by_loan_id(
      loan_id,
      this.onGetRepaymentsSuccess,
      this.onError
    );
    LoanRepaymentService.has_late_loan_repayments_by_loan_id(
      loan_id,
      this.onHasLateRepaymentsSuccess,
      this.onError
    );
    LoanActivityLogService.get_loan_activity_logs_by_loan_id(
      loan_id,
      this.onGetActivityLogsSuccess,
      this.onError
    );
  };

  componentDidMount = () => this.loadData();

  onGetLoanSuccess = (loan) =>
    this.setState({ loan: loan.data, isLoading: false });

  onGetLoanAlertsSuccess = (loanAlerts) => {
    let alerts = loanAlerts.data;
    if (alerts.length > 0) {
      this.setState({ lastLoanAlert: alerts[0] });
    }

    if (alerts.length >= 3) {
      this.setState({
        volumeTriggered:
          alerts[0].volume_triggered &&
          alerts[1].volume_triggered &&
          alerts[2].volume_triggered,
      });
    }
    this.setState({ loanAlerts: alerts });
  };

  onGetPledgeSuccess = (pledge) => this.setState({ pledge: pledge.data });

  onGetRepaymentsSuccess = (repayments) => this.setState({ repayments: repayments.data });
    

  onHasLateRepaymentsSuccess = (hasLateRepayments) =>
    this.setState({ hasLateRepayments: hasLateRepayments.data });

  onGetPledgeAdditionsSuccess = (pledgeAdditions) =>
    this.setState({ pledgeAdditions: pledgeAdditions.data });

  onGetActivityLogsSuccess = (activityLogs) =>
    this.setState({ activityLogs: activityLogs.data });

  onError = (error) =>
    this.setState({
      dialogOpen: true,
      dialogTitle: "Error Retrieving Loan",
      dialogMessage: this.props.t("general-error-retrieving-record"),
      dialogDescription: error.message,
      isError: true,
      isLoading: false,
      onDialogOkClick: this.onMessageDialogOk,
    });

  onMessageDialogOk = (e, { name }) => {
    const { isError } = this.state;
    this.setState({ dialogOpen: false });

    if (isError) this.props.history.push("/pages/loans");
  };

  onConfirmDialogNoClick = () => this.setState({ confirmDialogOpen: false });

  /* Close Loan */
  closeLoan = (loanId) =>
    this.setState({ closeDialogOpen: true, closeLoanId: loanId });
  onCloseDialogYesClick = (defaulted) => {
    const { closeLoanId } = this.state;
    this.setState({ closeDialogOpen: false });

    if (defaulted) {
      LoanService.default_loan(closeLoanId, this.loadData, this.onError);
    } else {
      LoanService.complete_loan(closeLoanId, this.loadData, this.onError);
    }
    // TODO doCloseLoan(closeLoanId, defaulted);
  };
  onCloseDialogCancelClick = () =>
    this.setState({ closeDialogOpen: false, closeLoanId: null });

  onLoanDefaulted = (res) => {
    if (res.data === null) {
      this.setState({
        dialogOpen: true,
        dialogTitle: "Error Defaulting Loan",
        dialogMessage: "There is an error defaulting the loan.",
        dialogDescription: "Please contact administrator.",
        isError: true,
        isLoading: false,
        onDialogOkClick: this.closeMessageDialog,
      });
    } else {
      this.setState({
        dialogOpen: true,
        dialogTitle: "Loan Closed",
        dialogMessage: "Loan is closed with DEFAULTED status.",
        dialogDescription: "",
        isError: false,
        isLoading: false,
        onDialogOkClick: () => this.closeMessageDialog(this.loadData),
      });
    }
  };

  onLoanClosed = (res) => {
    if (res.data === null) {
      this.setState({
        dialogOpen: true,
        dialogTitle: "Error Closing Loan",
        dialogMessage: "There is an error closing the loan.",
        dialogDescription: "Please contact administrator.",
        isError: true,
        isLoading: false,
        onDialogOkClick: this.closeMessageDialog,
      });
    } else {
      this.setState({
        dialogOpen: true,
        dialogTitle: "Loan Closed",
        dialogMessage: "Loan is closed with COMPLETED status.",
        dialogDescription: "",
        isError: false,
        isLoading: false,
        onDialogOkClick: () => this.closeMessageDialog(this.loadData),
      });
    }
  };

  closeMessageDialog = (afterClose) => {
    this.setState({ dialogOpen: false });
    if (afterClose !== null) afterClose();
  };

  /* Extend Loan */
  extendLoan = (loanId) =>
    this.setState({ extendLoanDialogOpen: true, extendLoanId: loanId });

  onExtendLoanDialogYesClick = (data) => {
    const { extendLoanId } = this.state;
    LoanService.extend_loan(
      extendLoanId,
      data,
      this.onLoanExtensionSuccess,
      this.orError
    );
  };
  onExtendLoanDialogCancelClick = () =>
    this.setState({ extendLoanDialogOpen: false, extendLoanId: null });

  onLoanExtensionSuccess = () => {
    this.setState({ extendLoanDialogOpen: false, extendLoanId: null });
    this.loadData();
  };

  /* Process Payment */
  processPayment = (repayment) =>
    this.setState({ repaymentDialogOpen: true, repayment: repayment });

  onRepaymentDialogYesClick = (is_late) => {
    const { repayment } = this.state;
    if (is_late) {
      LoanRepaymentService.flag_loan_repayment_as_late(
        repayment.id,
        this.onLoanRepaymentEdited,
        this.onError
      );
    } else {
      LoanRepaymentService.mark_loan_repayment_as_paid(
        repayment.id,
        this.onLoanRepaymentEdited,
        this.onError
      );
    }
    // TODO doCloseLoan(closeLoanId, defaulted);
  };
  onRepaymentDialogCancelClick = () =>
    this.setState({ repaymentDialogOpen: false, repayment: {} });

  onLoanRepaymentEdited = () => {
    this.setState({ repaymentDialogOpen: false, repayment: {} });
    this.loadData();
  };

  /* changeAlert */
  changeAlert = (pledge) => {
    let copy = {};
    Object.assign(copy, pledge);
    this.setState({ changeAlertDialogOpen: true, changeAlertPledge: copy });
  };

  onChangeAlertDialogYesClick = (updatedPledge) => {
    // const { changeAlertPledge } = this.state;
    this.setState({ changeAlertDialogOpen: false });

    // TODO doCloseLoan(closeLoanId, defaulted);
    PledgeService.update_pledge_alert(
      updatedPledge,
      this.loadData,
      this.onError
    );
  };
  onChangeAlertDialogCancelClick = () => {
    const { changeAlertPledge } = this.state;
    this.setState({ pledge: changeAlertPledge });
    this.setState({ changeAlertDialogOpen: false, changeAlertPledge: null });
  };

  /* add pledge */
  addPledge = () => {
    this.setState({ addPledgeDialogOpen: true });
  };

  onAddPledgeDialogYesClick = (newPledge) => {
    this.setState({ addPledgeDialogOpen: false });
    const { loan } = this.state;
    PledgeService.save_pledge_addition(
      loan.id,
      newPledge,
      this.loadData,
      this.onError
    );
    // TODO doCloseLoan(closeLoanId, defaulted);
  };
  onAddPledgeDialogCancelClick = () => {
    this.setState({ addPledgeDialogOpen: false });
  };

  /* log activity */
  logActivity = () => {
    this.setState({ logActivityDialogOpen: true });
  };

  onLogActivityDialogYesClick = (log) => {
    const { loan } = this.state;
    this.setState({ logActivityDialogOpen: false });
    LoanActivityLogService.save_loan_activity_log(
      loan.id,
      log.activity,
      this.loadData,
      console.log
    );
  };
  onLogActivityDialogCancelClick = () => {
    this.setState({ logActivityDialogOpen: false });
  };

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      onDialogOkClick,
      isError,
      isLoading,
      loan,
      loanAlerts,
      lastLoanAlert,
      volumeTriggered,
      pledge,
      pledgeAdditions,
      repayments,
      hasLateRepayments,
      repayment,
      activityLogs,
    } = this.state;
    const {
      closeDialogOpen,
      extendLoanDialogOpen,
      repaymentDialogOpen,
      changeAlertDialogOpen,
      addPledgeDialogOpen,
      logActivityDialogOpen,
    } = this.state;

    return (
      <Fragment>
        <CloseLoanDialog
          open={closeDialogOpen}
          onYesClick={this.onCloseDialogYesClick}
          onCancelClick={this.onCloseDialogCancelClick}
        />

        <ExtendLoanDialog
          open={extendLoanDialogOpen}
          onYesClick={this.onExtendLoanDialogYesClick}
          onCancelClick={this.onExtendLoanDialogCancelClick}
        />

        <RepaymentDialog
          open={repaymentDialogOpen}
          repayment={repayment}
          onYesClick={this.onRepaymentDialogYesClick}
          onCancelClick={this.onRepaymentDialogCancelClick}
        />

        <ChangeAlertsDialog
          open={changeAlertDialogOpen}
          onYesClick={this.onChangeAlertDialogYesClick}
          onCancelClick={this.onChangeAlertDialogCancelClick}
          pledge={pledge}
        />

        <AddPledgeDialog
          open={addPledgeDialogOpen}
          onYesClick={this.onAddPledgeDialogYesClick}
          onCancelClick={this.onAddPledgeDialogCancelClick}
        />

        <LogActivityDialog
          open={logActivityDialogOpen}
          onYesClick={this.onLogActivityDialogYesClick}
          onCancelClick={this.onLogActivityDialogCancelClick}
        />

        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={onDialogOkClick}
        />
        <SingleGridLayout>
          <DataLoader
            isLoading={isLoading}
            itemsLength={1}
            notFoundMessage={"There are no such Loan."}
          />
          {!isLoading && (
            <Grid>
              <Grid.Column width={8}>
                <Segment>
                  <Label color={"red"} attached={"top left"}>
                    Attention
                  </Label>
                  <Grid>
                    <Grid.Column width={8}>
                      <Segment>
                        <Label attached={"top left"}>Interest Alert</Label>
                      </Segment>
                      {hasLateRepayments && (
                        <Message negative>
                          <Message.Header>
                            Some Payments are LATE.
                          </Message.Header>
                        </Message>
                      )}
                    </Grid.Column>
                    <Grid.Column width={8}>
                      {" "}
                      <Segment>
                        <Label attached={"top left"}>Price Alert</Label>
                      </Segment>
                      {volumeTriggered && (
                        <Message negative>
                          <Message.Header>Volume Triggered.</Message.Header>
                        </Message>
                      )}
                      {lastLoanAlert && lastLoanAlert.margin_triggered && (
                        <Message negative>
                          <Message.Header>Margin Triggered.</Message.Header>
                        </Message>
                      )}
                      {lastLoanAlert && lastLoanAlert.alert_triggered && (
                        <Message negative>
                          <Message.Header>Alert Triggered.</Message.Header>
                        </Message>
                      )}
                      {lastLoanAlert && lastLoanAlert.currency_triggered && (
                        <Message negative>
                          <Message.Header>Currency Triggered</Message.Header>
                        </Message>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Segment>
                  <Label color={"blue"} attached={"top left"}>
                    Loan Status
                  </Label>
                  <Segment basic>
                    {StringUtils.capitalize(loan.loan_status)}
                  </Segment>
                  {loan.loan_status === "active" && (
                    <Segment basic textAlign={"right"}>
                      <Button
                        color={"green"}
                        onClick={() => this.closeLoan(loan.id)}
                      >
                        Close Loan
                      </Button>
                    </Segment>
                  )}
                </Segment>
                <Segment>
                  <Label color={"blue"} attached={"top left"}>
                    Loan Details
                  </Label>
                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Account</Table.Cell>
                        <Table.Cell>{loan.account_name}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Principal</Table.Cell>
                        <Table.Cell>{loan.principal}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Interest Rate</Table.Cell>
                        <Table.Cell>
                          {loan.interest_rate} % == {loan.interest}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Late Interest Rate</Table.Cell>
                        <Table.Cell>
                          {loan.late_interest_rate} % == {loan.late_interest}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Initial Payment Percentage</Table.Cell>
                        <Table.Cell>
                          {loan.initial_payment_percentage} %
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Repayment Months</Table.Cell>
                        <Table.Cell>{loan.repayment_months}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Lending Value</Table.Cell>
                        <Table.Cell>{loan.lending_value} %</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Start Date</Table.Cell>
                        <Table.Cell>
                          {StringUtils.toLocaleDateString(loan.active_date)}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {loan.loan_status === "active" && (
                    <Segment basic textAlign={"right"}>
                      <Button
                        color={"green"}
                        onClick={() => this.extendLoan(loan.id)}
                      >
                        Extend Loan
                      </Button>
                    </Segment>
                  )}
                </Segment>

                <Segment>
                  <Label color={"blue"} attached={"top left"}>
                    Interest Schedule
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Due Date</Table.HeaderCell>
                        <Table.HeaderCell>Payment</Table.HeaderCell>
                        <Table.HeaderCell>Ext</Table.HeaderCell>
                        <Table.HeaderCell>Paid Date</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {repayments.map((repayment) => (
                        <Table.Row key={repayment.id}>
                          <Table.Cell>
                            {StringUtils.toLocaleDateString(repayment.due_date)}
                          </Table.Cell>
                          {repayment.late_interest_applied && repayment.extension_counter==null && (<Table.Cell>{repayment.payment_amount + loan.late_interest}</Table.Cell>)}
                          {repayment.late_interest_applied && repayment.extension_counter!=null && (<Table.Cell>{repayment.payment_amount + repayment.late_interest}</Table.Cell>)}
                          {!repayment.late_interest_applied && (<Table.Cell>{repayment.payment_amount}</Table.Cell>)}
                          {repayment.extension_counter==null && (<Table.Cell>-</Table.Cell>)}
                          {repayment.extension_counter!=null && (<Table.Cell>{repayment.extension_counter}</Table.Cell>)}
                          <Table.Cell>
                            {StringUtils.toLocaleDateString(
                              repayment.payment_date,
                              "Unpaid"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {loan.loan_status === "active" &&
                              repayment.payment_date == null &&
                                !repayment.late_interest_applied && (
                                  <Icon
                                    name={"dollar"}
                                    color={"blue"}
                                    inverted
                                    onClick={() => this.processPayment(repayment)}
                                  />
                                )}
                            {loan.loan_status === "active" &&
                              repayment.payment_date == null &&
                                repayment.late_interest_applied && (
                                  <Icon
                                    name={"dollar"}
                                    color={"red"}
                                    inverted
                                    onClick={() => this.processPayment(repayment)}
                                  />
                                )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Security
                  </Label>
                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Security Code</Table.Cell>
                        <Table.Cell>{pledge.security_code}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Volume Alert</Table.Cell>
                        <Table.Cell>{pledge.volume_trigger}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Margin Percentage</Table.Cell>
                        <Table.Cell>{pledge.margin_percentage} %</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Alert Percentage</Table.Cell>
                        <Table.Cell>{pledge.alert_percentage} %</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Native Currency</Table.Cell>
                        <Table.Cell>{pledge.native_currency}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Currency Trigger</Table.Cell>
                        <Table.Cell>{pledge.currency_trigger}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {loan.loan_status === "active" && (
                    <Segment basic textAlign={"right"}>
                      <Button
                        color={"green"}
                        onClick={() => this.changeAlert(pledge)}
                      >
                        Change Alerts
                      </Button>
                    </Segment>
                  )}
                </Segment>
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Pledges
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Volume</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Native Price</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{pledge.volume}</Table.Cell>
                        <Table.Cell>{pledge.price}</Table.Cell>
                        <Table.Cell>{pledge.native_price}</Table.Cell>
                        <Table.Cell>{pledge.pledge_value}</Table.Cell>
                      </Table.Row>
                      {pledgeAdditions.map((pledgeAddition) => (
                        <Table.Row key={pledgeAddition.id}>
                          <Table.Cell>{pledgeAddition.volume}</Table.Cell>
                          <Table.Cell>{pledgeAddition.price}</Table.Cell>
                          <Table.Cell>{pledgeAddition.native_price}</Table.Cell>
                          <Table.Cell>{pledgeAddition.pledge_value}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  {loan.loan_status === "active" && (
                    <Segment basic textAlign={"right"}>
                      <Button color={"green"} onClick={this.addPledge}>
                        Add Pledge
                      </Button>
                    </Segment>
                  )}
                </Segment>
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Last 10 Price Changes
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell rowSpan={2}>Date</Table.HeaderCell>
                        <Table.HeaderCell rowSpan={2}>
                          Native Price
                        </Table.HeaderCell>
                        <Table.HeaderCell>Margin Triggered</Table.HeaderCell>
                        <Table.HeaderCell>Volume</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Alert Triggered</Table.HeaderCell>
                        <Table.HeaderCell>Volume Triggered</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {loanAlerts.map((loanAlert) => (
                        <Fragment>
                          <Table.Row>
                            <Table.Cell rowSpan={2}>
                              {StringUtils.toLocaleDateString(
                                loanAlert.inserted_at
                              )}
                            </Table.Cell>
                            <Table.Cell rowSpan={2}>
                              {loanAlert.native_price}
                            </Table.Cell>
                            <Table.Cell>
                              {StringUtils.humanizeBoolean(
                                loanAlert.margin_triggered
                              )}
                            </Table.Cell>
                            <Table.Cell>{loanAlert.volume}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              {StringUtils.humanizeBoolean(
                                loanAlert.alert_triggered
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {StringUtils.humanizeBoolean(
                                loanAlert.volume_triggered
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
                <Segment>
                  <Label color={"black"} attached={"top left"}>
                    Activity Log
                  </Label>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Activity</Table.HeaderCell>
                        <Table.HeaderCell>By</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {activityLogs.map((activityLog) => (
                        <Fragment>
                          <Table.Row key={activityLog.id}>
                            <Table.Cell>{activityLog.activity}</Table.Cell>
                            <Table.Cell>
                              {StringUtils.emailToName(activityLog.user_email)}
                            </Table.Cell>
                            <Table.Cell>
                              {StringUtils.toLocaleDateString(
                                activityLog.inserted_at
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                  {loan.loan_status === "active" && (
                    <Segment basic textAlign={"right"}>
                      <Button
                        color={"green"}
                        onClick={() => this.logActivity()}
                      >
                        Add Log
                      </Button>
                    </Segment>
                  )}
                </Segment>
              </Grid.Column>
            </Grid>
          )}
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(LoanViewPage);
