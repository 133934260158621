import PropTypes from "prop-types";
import React from "react";
import { Menu, Sidebar } from "semantic-ui-react";

const userNavigateTo = (e, { name }) => {
  const target = (targetName) => {
    switch (targetName) {
      case "LoansPage":
        return "/#/pages/loans";
      case "LoanNewPage":
        return "/#/pages/loans/new";
      case "LookupLiborRatesPage":
        return "/#/pages/lookup-libor-rates";
      case "PledgesPage":
        return "/#/pages/pledges";
      case "AccountsPage":
        return "/#/pages/accounts";
      case "InterestCalculatorPage":
        return "/#/pages/calculator";
      case "WebsitePage":
        return "https://charismatic.fund";
      default:
        return "/#/pages/dashboard";
    }
  };

  if (name === "WebsitePage") window.open(target(name), "_blank");
  else window.location.href = target(name);
};

export const UserSidebar = ({ visible }) => {
  return (
    <Sidebar
      as={Menu}
      animation={"push"}
      direction={"left"}
      visible={visible}
      inverted
      vertical
      width={"wide"}
    >
      <Menu.Item as={"a"} name={"DashboardPage"} onClick={userNavigateTo}>
        Dashboard
      </Menu.Item>

      <Menu.Item as={"a"} name={"LoanNewPage"} onClick={userNavigateTo}>
        New Loan
      </Menu.Item>

      <Menu.Item as={"a"} name={"LoansPage"} onClick={userNavigateTo}>
        Loans
      </Menu.Item>

      <Menu.Item>
        Setup
        <Menu.Item
          as={"a"}
          name={"InterestCalculatorPage"}
          onClick={userNavigateTo}
        >
          Interest Calculator
        </Menu.Item>
        <Menu.Item
          as={"a"}
          name={"LookupLiborRatesPage"}
          onClick={userNavigateTo}
        >
          Set Libor Rate
        </Menu.Item>
      </Menu.Item>

      <Menu.Item as={"a"} name={"PledgesPage"} onClick={userNavigateTo}>
        Pledges
      </Menu.Item>

      <Menu.Item as={"a"} name={"AccountsPage"} onClick={userNavigateTo} icon>
        Accounts
      </Menu.Item>

      <Menu.Item as={"a"} name={"WebsitePage"} onClick={userNavigateTo} icon>
        Website
      </Menu.Item>
    </Sidebar>
  );
};

UserSidebar.propTypes = {
  visible: PropTypes.bool,
};
