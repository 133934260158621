import axios from "axios";

export const LoanActivityLogService = {
  get_loan_activity_logs_by_loan_id: (loan_id, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .get(`/api/loans/${loan_id}/activity-logs`, { headers: headers })
      .then(onSuccess)
      .catch(onError);
  },
  save_loan_activity_log: (loan_id, activity, onSuccess, onError) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    };
    axios
      .post(
        `/api/loans/${loan_id}/activity-logs`,
        { activity: activity },
        { headers: headers }
      )
      .then(onSuccess)
      .catch(onError);
  },
};
