import React, { Component, Fragment } from "react";
import { Segment, Label, Form, Grid, Table, Button } from "semantic-ui-react";
import SingleGridLayout from "../../layout/single-grid";
import { LoanService } from "../../services/loan-service";
import { LookupLiborRateService } from "../../services/lookup-service";
import { MessageDialog } from "../../components/message-dialog";
import approve from "approvejs";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";

class InterestCalculatorPage extends Component {
  state = {
    step: 1,
    dialogOpen: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogDescription: "",
    isError: false,
  };

  loan = {
    principal: 100000,
    interest_rate: 9,
    late_interest_rate: 12,
    initial_payment_percentage: 2,
    repayment_months: 12,
    active_date: format(new Date(), "yyyy-MM-dd"),
    current_libor_rate: 0,
    interest: 0,
    late_interest: 0,
    payments: [],
  };

  loadCurrentLiborRate = () =>
    LookupLiborRateService.get_current_lookup_libor_rate(
      this.onLiborRateLoaded,
      this.onError
    );

  onLiborRateLoaded = (liborRate) => {
    this.loan["current_libor_rate"] = liborRate.data["libor_rate"];
    this.setState({ loan: this.loan });
    this.calculatePayments();
  };

  calculatePayments = () => {
    const { loan } = this.state;
    LoanService.calculate_payments(
      loan,
      this.onPaymentsCalculated,
      this.onError
    );
  };

  onPaymentsCalculated = (calculation) => {
    this.loan["interest"] = calculation.data["interest"];
    this.loan["late_interest"] = calculation.data["late_interest"];
    this.loan["payments"] = calculation.data["payments"];
    this.setState({ loan: this.loan });
  };

  componentDidMount = () => {
    this.loadCurrentLiborRate();
  };

  onError = (error) => console.log(error);

  handleChangeOnLoan = (e, { name, value }) => {
    this.loan[name] = value;
    this.setState({ loan: this.loan });
  };

  doValidateImpl = (value, rule, error_key, error_message) => {
    if (approve.value(value, rule).approved) {
      this.setState({ [error_key]: false });
      return true;
    } else {
      this.setState({ [error_key]: error_message });
      return false;
    }
  };

  doValidateLoan = () => {
    const {
      principal,
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
      lending_value,
      active_date,
    } = this.loan;

    let requiredRule = {
      required: true,
    };

    let isValid2 = this.doValidateImpl(
      principal,
      requiredRule,
      "principal_error",
      "Please enter the Principal."
    );
    let isValid3 = this.doValidateImpl(
      interest_rate,
      requiredRule,
      "interest_rate_error",
      "Please enter the Interest Rate."
    );
    let isValid4 = this.doValidateImpl(
      late_interest_rate,
      requiredRule,
      "late_interest_rate_error",
      "Please enter the Late Interest Rate."
    );
    let isValid5 = this.doValidateImpl(
      initial_payment_percentage,
      requiredRule,
      "initial_payment_percentage_error",
      "Please enter the Inital Payment Percentage."
    );
    let isValid6 = this.doValidateImpl(
      repayment_months,
      requiredRule,
      "repayment_months_error",
      "Please enter the Repayment Months."
    );

    let isValid7 = this.doValidateImpl(
      lending_value,
      requiredRule,
      "lending_value_error",
      "Please select the Lending Value."
    );

    let isValid8 = this.doValidateImpl(
      active_date,
      requiredRule,
      "active_date_error",
      "Please set the Start Date."
    );

    return (
      isValid2 &&
      isValid3 &&
      isValid4 &&
      isValid5 &&
      isValid6 &&
      isValid7 &&
      isValid8
    );
  };

  onMessageDialogOk = (e, { name }) => this.setState({ dialogOpen: false });

  render = () => {
    const {
      dialogOpen,
      dialogTitle,
      dialogMessage,
      dialogDescription,
      isError,
    } = this.state;
    const {
      principal,
      interest_rate,
      late_interest_rate,
      initial_payment_percentage,
      repayment_months,
      active_date,
      current_libor_rate,
      interest,
      late_interest,
      payments,
    } = this.loan;
    const {
      principal_error,
      interest_rate_error,
      late_interest_rate_error,
      initial_payment_percentage_error,
      repayment_months_error,
      active_date_error,
    } = this.state;

    return (
      <Fragment>
        <MessageDialog
          open={dialogOpen}
          title={dialogTitle}
          message={dialogMessage}
          description={dialogDescription}
          isError={isError}
          onOkClick={this.onMessageDialogOk}
        />
        <SingleGridLayout>
          <Grid>
            <Grid.Column width={10}>
              <Segment padded>
                <Label color={"black"} attached={"top left"}>
                  Loan Parameters
                </Label>
                <Form id={"loan-details-form"} size="large">
                  <Form.Input
                    label={"Principal"}
                    name={"principal"}
                    value={principal}
                    type={"number"}
                    step={50000}
                    error={principal_error}
                    onChange={this.handleChangeOnLoan}
                  />
                  <Form.Input
                    label={"Interest Rate"}
                    name={"interest_rate"}
                    value={interest_rate}
                    type={"number"}
                    step={"0.1"}
                    error={interest_rate_error}
                    onChange={this.handleChangeOnLoan}
                  />
                  <Form.Input
                    label={"Late Interest Rate"}
                    name={"late_interest_rate"}
                    value={late_interest_rate}
                    type={"number"}
                    step={"0.1"}
                    error={late_interest_rate_error}
                    onChange={this.handleChangeOnLoan}
                  />
                  <Segment padded>
                    <Label color={"red"} attached={"top"}>
                      Late Interest
                    </Label>
                    <Segment basic>{late_interest}</Segment>
                  </Segment>
                  <Form.Input
                    label={"Current Libor Rate"}
                    name={"current_libor_rate"}
                    value={current_libor_rate}
                    readeOnly
                  />
                  <Form.Input
                    label={"Initial Payment Percentage"}
                    name={"initial_payment_percentage"}
                    value={initial_payment_percentage}
                    type={"number"}
                    step={"0.1"}
                    error={initial_payment_percentage_error}
                    onChange={this.handleChangeOnLoan}
                  />
                  <Form.Input
                    label={"Repayment Months"}
                    name={"repayment_months"}
                    value={repayment_months}
                    type={"number"}
                    step={"1"}
                    error={repayment_months_error}
                    onChange={this.handleChangeOnLoan}
                  />
                  <Form.Input
                    label={"Start Date"}
                    name={"active_date"}
                    value={active_date}
                    type={"date"}
                    onChange={this.handleChangeOnLoan}
                    error={active_date_error}
                  />
                  <Segment padded>
                    <Label color={"teal"} attached={"top"}>
                      Total Interest
                    </Label>
                    <Segment basic>{interest}</Segment>
                  </Segment>
                </Form>
              </Segment>
              <Segment clearing>
                <Button
                  floated={"right"}
                  color={"blue"}
                  onClick={this.calculatePayments}
                >
                  Calculate
                </Button>
              </Segment>
            </Grid.Column>

            <Grid.Column width={6}>
              <Segment padded>
                <Label color={"black"} attached={"top left"}>
                  Interest Schedule
                </Label>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Payment #</Table.HeaderCell>
                      <Table.HeaderCell>Payment</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.map((payment, index) => (
                      <Table.Row>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell>{payment}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid>
        </SingleGridLayout>
      </Fragment>
    );
  };
}

export default withTranslation("translations")(InterestCalculatorPage);
